import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TableWrapper, Wrapper } from './clients';
import { Table, Typography } from 'antd';
import { useCustomersListSelector } from '../state/customers/customersList/selectors';
import { useAppDispatch } from '../state';
import {
  editCustomerAction,
  loadAllCustomersAction,
} from '../state/customers/customersList/actions';
import { IBaseProfile } from '../types/profile';
import { baseProfileColumns, mapBaseProfileToTable } from '../utils/profiles';
import {
  EditProfileDrawer,
  IBaseProfileForm,
} from '../components/base-profiles/edit-profile-drawer';

export const Customers: FC = () => {
  const dispatch = useAppDispatch();
  const { isPending, isError, isLoaded, customers } =
    useCustomersListSelector();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<
    IBaseProfile | undefined
  >();

  const handleSelectCustomer = useCallback((customer: IBaseProfile) => {
    setSelectedCustomer(customer);
    setModalOpen(true);
  }, []);

  useEffect(() => {
    if (!isLoaded && !isError && !isPending) {
      dispatch(loadAllCustomersAction());
    }
  }, [isLoaded, isPending, isError]);

  const onModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const dataSource = useMemo(
    () =>
      customers && customers.length > 0
        ? customers.map((el, index) =>
            mapBaseProfileToTable(el, index, handleSelectCustomer)
          )
        : [],
    [customers]
  );

  const handleFinishEditForm = useCallback(
    (values: IBaseProfileForm) => {
      if (selectedCustomer) {
        dispatch(
          editCustomerAction({
            newValues: values,
            customer: {
              ...selectedCustomer,
              ...values,
            },
            successCallback: () => {
              onModalClose();
            },
          })
        );
      }
    },
    [selectedCustomer]
  );

  return (
    <Wrapper>
      <Typography.Title level={2}>Кастомеры в системе</Typography.Title>
      <TableWrapper>
        <Table
          dataSource={dataSource}
          columns={baseProfileColumns}
          pagination={false}
          loading={isPending}
          locale={{
            emptyText: 'Нет данных',
          }}
        />
      </TableWrapper>
      <EditProfileDrawer
        handleFinishForm={handleFinishEditForm}
        onClose={onModalClose}
        selectedClient={selectedCustomer}
        open={modalOpen}
      />
    </Wrapper>
  );
};
