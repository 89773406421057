import { createAction } from '@reduxjs/toolkit';
import { IActionFirstCreate } from '../../../components/home-settings/action-drawer';
import { IActionFromSalon } from './slice';

export const getActionsListAction = createAction('actions/get/all');

export const createActionAction = createAction<{
  errorCallBack?: () => void;
  successCallBack?: () => void;
  data: IActionFirstCreate;
}>('action/create');

export const editActionAction = createAction<{
  errorCallBack?: () => void;
  successCallBack?: () => void;
  data: Partial<IActionFirstCreate>;
  originalFileObj: IActionFromSalon;
}>('action/edit');

export const deleteActionAction = createAction<{
  errorCallBack?: () => void;
  successCallBack?: () => void;
  id: string;
}>('action/delete');
