import { call, put } from 'redux-saga/effects';
import {
  endClientsWorker,
  setData,
  setError,
  startClientsWorker,
} from '../../state/clients/clientsList/slice';
import { ClientsAPI } from '../../api/clients';
import { message } from 'antd';

export function* loadAllWorker(): Generator {
  try {
    yield put(startClientsWorker());

    const response: any = yield call(ClientsAPI.getAll);

    yield put(setData(response.data));
    yield put(endClientsWorker());
  } catch (e) {
    yield put(setError());
    yield put(endClientsWorker());
    yield call(message.error, 'Ошибка при загрузке списка клиентов');
  }
}
