import {
  editActionAction,
  getActionsListAction,
} from '../../../state/home-settings/actionsList/actions';
import { call, delay, put } from 'redux-saga/effects';
import {
  endActionsListWorker,
  setActionsError,
  startActionsListWorker,
} from '../../../state/home-settings/actionsList/slice';
import { IActionFinalCreate } from '../../../components/home-settings/action-drawer';
import { HomeSettingsAPI } from '../../../api/home-settings';
import { handleUploadActionFiles } from './createActionWorker';
import { CommonAPI, IMediaType } from '../../../api/common';

export function* editActionWorker(
  action: ReturnType<typeof editActionAction>
): Generator {
  try {
    yield put(startActionsListWorker());

    let payloadData: Partial<IActionFinalCreate> = {
      ...action.payload.data,
      fullImage: action.payload.originalFileObj.fullImage,
      thumbs: action.payload.originalFileObj.thumbs,
    };

    if (action.payload.data.thumbs && action.payload.data.fullImage) {
      const filesResponse: any = yield call(
        handleUploadActionFiles,
        action.payload.data.thumbs,
        action.payload.data.fullImage
      );

      payloadData = {
        ...payloadData,
        thumbs: filesResponse[0].data.url,
        fullImage: filesResponse[1].data.url,
      };
    } else if (action.payload.data.thumbs) {
      const thumbFileResponse: any = yield call(
        CommonAPI.uploadFile,
        IMediaType.BANNER,
        action.payload.data.thumbs
      );

      payloadData = {
        ...payloadData,
        thumbs: thumbFileResponse.data.url,
      };

      yield delay(1000);
    } else if (action.payload.data.fullImage) {
      const fullFileResponse: any = yield call(
        CommonAPI.uploadFile,
        IMediaType.BANNER,
        action.payload.data.fullImage
      );

      payloadData = {
        ...payloadData,
        fullImage: fullFileResponse.data.url,
      };

      yield delay(1000);
    }

    yield call(
      HomeSettingsAPI.editAction,
      payloadData,
      action.payload.originalFileObj.id
    );

    yield put(getActionsListAction());

    yield put(endActionsListWorker());
    if (action.payload.successCallBack) {
      yield call(action.payload.successCallBack);
    }
  } catch (e) {
    yield put(setActionsError());
    yield put(endActionsListWorker());
    if (action.payload.errorCallBack) {
      yield call(action.payload.errorCallBack);
    }
  }
}
