import { combineReducers } from '@reduxjs/toolkit';
import { homeBannersReducer } from './banners/slice';
import { actionsListReducer } from './actionsList/slice';
import { actionsGeneralReducer } from './actionsGeneral/slice';

export const homeSettingsReducer = combineReducers({
  banners: homeBannersReducer,
  actions: actionsListReducer,
  actionsGeneral: actionsGeneralReducer,
});
