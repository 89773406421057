import { takeLeading } from 'redux-saga/effects';
import {
  editMasterAction,
  loadAllMastersAction,
} from '../../state/masters/mastersList/actions';
import { loadAllWorker } from './loadAllWorker';
import { editWorker } from './editWorker';

export function* mastersSaga(): Generator {
  yield takeLeading(loadAllMastersAction, loadAllWorker);
  yield takeLeading(editMasterAction, editWorker);
}
