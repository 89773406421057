import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormInstance,
  Image,
  Input,
  InputNumber,
  Upload,
} from 'antd';
import { IActionForm } from './action-drawer';
import styled from '@emotion/styled';
import { UploadOutlined } from '@ant-design/icons';
import { normFile } from './banner-ce-form';

const FormWrapper = styled('div')`
  width: 100%;
`;

interface Props {
  form: FormInstance<IActionForm>;
  handleFinishForm: (values: IActionForm) => void;
  mode: 'create' | 'edit';
  defaultFilePreviewUrl?: string;
  defaultFileFullUrl?: string;
}

export const ActionCeForm: FC<Props> = ({
  form,
  handleFinishForm,
  mode,
  defaultFilePreviewUrl,
  defaultFileFullUrl,
}) => {
  const [filePreviewList, setFilePreviewList] = useState<File[]>([]);
  const [filePreviewUrl, setFilePreviewUrl] = useState<string | undefined>(
    defaultFilePreviewUrl
  );

  const [fileFullList, setFileFullList] = useState<File[]>([]);
  const [fileFullUrl, setFileFullUrl] = useState<string | undefined>(
    defaultFileFullUrl
  );

  useEffect(() => {
    if (defaultFileFullUrl) {
      setFileFullUrl(defaultFileFullUrl);
    }
  }, [defaultFileFullUrl]);

  useEffect(() => {
    if (defaultFilePreviewUrl) {
      setFilePreviewUrl(defaultFilePreviewUrl);
    }
  }, [defaultFilePreviewUrl]);

  useEffect(() => {
    if (fileFullList.length) {
      const fr = new FileReader();

      fr.onload = () => {
        setFileFullUrl(fr.result as string);
      };

      fr.readAsDataURL(fileFullList[0]);
    } else {
      if (defaultFileFullUrl) {
        setFileFullUrl(defaultFileFullUrl);
      } else {
        setFileFullUrl(undefined);
      }
    }
  }, [fileFullList]);

  useEffect(() => {
    if (filePreviewList.length) {
      const fr = new FileReader();

      fr.onload = () => {
        setFilePreviewUrl(fr.result as string);
      };

      fr.readAsDataURL(filePreviewList[0]);
    } else {
      if (defaultFilePreviewUrl) {
        setFilePreviewUrl(defaultFilePreviewUrl);
      } else {
        setFilePreviewUrl(undefined);
      }
    }
  }, [filePreviewList]);

  return (
    <FormWrapper>
      <Form<IActionForm>
        form={form}
        onFinish={handleFinishForm}
        layout={'vertical'}
      >
        <Form.Item
          name="thumbs"
          label="Фото превью"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: mode === 'create',
              message: 'Файл обязателен',
            },
          ]}
        >
          <Upload
            beforeUpload={(file) => {
              return new Promise((res) => {
                res(file);
              });
            }}
            customRequest={(e) => {
              setFilePreviewList([e.file as File]);
            }}
            showUploadList={false}
            accept={'.jpg, .jpeg, .png'}
          >
            <Button icon={<UploadOutlined />}>Выберите файл</Button>
          </Upload>
        </Form.Item>
        <div>
          {filePreviewUrl && (
            <Image
              style={{
                objectFit: 'contain',
              }}
              width={300}
              src={filePreviewUrl}
            />
          )}
          {filePreviewList[0] ? filePreviewList[0].name : ''}
        </div>
        <Form.Item
          name="fullImage"
          label="Полное фото"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[
            {
              required: mode === 'create',
              message: 'Файл обязателен',
            },
          ]}
          style={{
            margin: '20px 0',
          }}
        >
          <Upload
            beforeUpload={(file) => {
              return new Promise((res) => {
                res(file);
              });
            }}
            customRequest={(e) => {
              setFileFullList([e.file as File]);
            }}
            showUploadList={false}
            accept={'.jpg, .jpeg, .png'}
          >
            <Button icon={<UploadOutlined />}>Выберите файл</Button>
          </Upload>
        </Form.Item>
        <div>
          {fileFullUrl && (
            <Image
              style={{
                objectFit: 'contain',
              }}
              width={300}
              src={fileFullUrl}
            />
          )}
          {fileFullList[0] ? fileFullList[0].name : ''}
        </div>
        <Form.Item
          name="title"
          label="Название"
          rules={[
            {
              required: true,
              message: 'Поле обязательно для заполнения',
            },
          ]}
        >
          <Input placeholder={'Введите'} />
        </Form.Item>
        <Form.Item name="description" label="Описание">
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 6 }}
            placeholder={'Введите'}
          />
        </Form.Item>
        <Form.Item name="terms" label="Terms">
          <Input placeholder={'Введите'} />
        </Form.Item>
        <Form.Item name="serviceStatus" label="ServiceStatus">
          <InputNumber min={0} max={100} placeholder={'Введите'} />
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};
