export enum Colors {
  WHITE100 = '#FFFFFF',
  WHITE100_08 = 'rgba(255, 255, 255, 0.8)',
  WHITE200 = '#FBFBFB',
  WHITE300 = '#F5F5F5',
  WHITE400 = '#F6F6F6',
  WHITE900 = '#D6D6D6',
  ACCENT_PRIMARY = '#f5542c',
  ACCENT_SECONDARY = '#252237',
  ACCENT_SUCCESS = '#2AD245',
  ACCENT_WARNING = '#FCBD44',
  ACCENT_DANGER = '#F34F4F',
  TEXT_COMMON = '#121212',
  TEXT_DISABLED = '#898989',
  TEXT_HEADING = '#333333',
  TEXT_SECONDARY = '#666666',
  DISABLED_ICON = '#7A7979',
  SMOOTH_PRIMARY = '#4b326b',
  TRANSPARENT = 'transparent',
}

export interface MainColors {
  WHITE: {
    C100: Colors;
    C200: Colors;
    C300: Colors;
    C400: Colors;
    C900: Colors;
    C100_08: Colors;
  };
  ACCENT: {
    PRIMARY: Colors;
    SECONDARY: Colors;
    SUCCESS: Colors;
    WARNING: Colors;
    DANGER: Colors;
  };
  GRADIENT: {
    PRIMARY: string;
  };
  TEXT: {
    COMMON_TEXT: Colors;
    DISABLED: Colors;
    HEADING: Colors;
    SECONDARY: Colors;
    PRIMARY: Colors;
  };
  TRANSPARENT: Colors;
}

export const MainColorsLight: MainColors = {
  WHITE: {
    C100: Colors.WHITE100,
    C200: Colors.WHITE200,
    C300: Colors.WHITE300,
    C400: Colors.WHITE400,
    C900: Colors.WHITE900,
    C100_08: Colors.WHITE100_08,
  },
  ACCENT: {
    PRIMARY: Colors.ACCENT_PRIMARY,
    SECONDARY: Colors.ACCENT_SECONDARY,
    SUCCESS: Colors.ACCENT_SUCCESS,
    WARNING: Colors.ACCENT_WARNING,
    DANGER: Colors.ACCENT_DANGER,
  },
  GRADIENT: {
    PRIMARY: 'linear-gradient(89.55deg, #252237 0%, #6D30BA 100%)',
  },
  TEXT: {
    COMMON_TEXT: Colors.TEXT_COMMON,
    DISABLED: Colors.TEXT_DISABLED,
    HEADING: Colors.TEXT_HEADING,
    SECONDARY: Colors.TEXT_SECONDARY,
    PRIMARY: Colors.ACCENT_PRIMARY,
  },
  TRANSPARENT: Colors.TRANSPARENT,
};

export enum Sizes {
  BORDER_RADIUS = '4px',
  BORDER_RADIUS_SM = '2px',
}
