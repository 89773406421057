import React, { FC } from 'react';
import { Button } from 'antd';
import styled from '@emotion/styled';

const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Home: FC = () => {
  return (
    <Wrapper>
      <Button type={'primary'}>Hello world!</Button>
    </Wrapper>
  );
};
