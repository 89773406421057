export enum Paths {
  BASE = '/',
  CLIENTS = '/clients',
  LOGIN = '/login',
  CUSTOMERS = '/customers',
  MASTERS = '/masters',
  SALONS = '/salons',
  HOME_SETTINGS = '/home-settings',
  SELF_EMPLOYERS = '/self-employers',
}
