import { editBannerAction } from '../../../state/home-settings/banners/actions';
import { call, delay, put } from 'redux-saga/effects';
import {
  editBanner,
  endBannersWorker,
  startBannersWorker,
} from '../../../state/home-settings/banners/slice';
import { HomeSettingsAPI } from '../../../api/home-settings';

export function* editBannerWorker(
  action: ReturnType<typeof editBannerAction>
): Generator {
  try {
    yield put(startBannersWorker());
    yield delay(1000);

    const response: any = yield call(
      HomeSettingsAPI.editBanner,
      action.payload.originalBannerObj.id,
      {
        mediaId: action.payload.newData.mediaId,
        url: action.payload.newData.url,
      }
    );

    yield put(editBanner(response.data));

    yield put(endBannersWorker());
    if (action.payload.successCallBack) {
      yield call(action.payload.successCallBack);
    }
  } catch (e) {
    yield put(endBannersWorker());
    if (action.payload.errorCallBack) {
      yield call(action.payload.errorCallBack);
    }
  }
}
