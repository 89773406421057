import { takeLeading } from 'redux-saga/effects';
import {
  createActionAction,
  deleteActionAction,
  editActionAction,
  getActionsListAction,
} from '../../../state/home-settings/actionsList/actions';
import { actionsAllLoadWorker } from './actionsAllLoadWorker';
import { createActionWorker } from './createActionWorker';
import { deleteActionWorker } from './deleteActionWorker';
import { editActionWorker } from './editActionWorker';
import { generalLoadAllWorker } from './generalLoadAllWorker';
import { loadAllActionsGeneralAction } from '../../../state/home-settings/actionsGeneral/actions';

export function* actionsSaga(): Generator {
  yield takeLeading(getActionsListAction, actionsAllLoadWorker);
  yield takeLeading(createActionAction, createActionWorker);
  yield takeLeading(deleteActionAction, deleteActionWorker);
  yield takeLeading(editActionAction, editActionWorker);
  yield takeLeading(loadAllActionsGeneralAction, generalLoadAllWorker);
}
