import { AxiosResponse } from 'axios';
import { baseAPIInstance } from '../index';
import { IMeSuccessResponse } from '../../state/profile/slice';

interface IProfileAPI {
  me: () => Promise<AxiosResponse<IMeSuccessResponse>>;
}

export const ProfileAPI: IProfileAPI = {
  me: async () => {
    return baseAPIInstance.get('/auth/me');
  },
};
