import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from '../../types/roles';

export interface ILoginSuccessResponse {
  accessToken: string;
  refreshToken: string;
}

export interface ILoginBaseError {
  status: number;
}

export interface IDecodedToken {
  exp: number;
  iat: number;
  id: string;
  role: Role | Role[];
}

type InitialType =
  | {
      authed: false;
      data: null;
      error: ILoginBaseError & {
        isError: boolean;
      };
      decoded: null;
    }
  | {
      authed: true;
      data: ILoginSuccessResponse;
      error: ILoginBaseError & {
        isError: boolean;
      };
      decoded: IDecodedToken;
    };

const initialState: InitialType = {
  authed: false,
  data: null,
  error: {
    isError: false,
    status: -1,
  },
  decoded: null,
};

const userAuthSlice = createSlice({
  name: 'user/auth',
  initialState: initialState as InitialType,
  reducers: {
    setAuth(
      state,
      action: PayloadAction<{
        data: ILoginSuccessResponse;
        decoded: IDecodedToken;
      }>
    ) {
      state.data = action.payload.data;
      state.decoded = action.payload.decoded;
      state.authed = true;
    },
    setAuthError(state, action: PayloadAction<ILoginBaseError>) {
      state.authed = false;
      state.error = {
        isError: true,
        status: action.payload.status,
      };
    },
    clearError(state) {
      state.error = {
        isError: false,
        status: -1,
      };
    },
    handleLogout(state) {
      state.authed = false;
      state.error = {
        isError: false,
        status: -1,
      };
      state.decoded = null;
      state.data = null;
    },
  },
});

export const { setAuth, setAuthError, clearError, handleLogout } =
  userAuthSlice.actions;
export const userAuthReducer = userAuthSlice.reducer;
