import { all, call, spawn } from 'redux-saga/effects';
import { bannersSaga } from './banners/bannersSaga';
import { actionsSaga } from './actions/actionsSaga';

export function* homeSettingsSaga(): Generator {
  const homeSagas = [bannersSaga, actionsSaga];

  yield all(
    homeSagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            console.log(e);
          }
        }
      })
    )
  );
}
