import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IInitialState {
  fullAppLoading: boolean;
  baseContainerLoading: boolean;
}

const initialState: IInitialState = {
  fullAppLoading: false,
  baseContainerLoading: false,
};

const loadingSlice = createSlice({
  name: 'app/loading',
  initialState,
  reducers: {
    removeAllLoadings(state) {
      state.baseContainerLoading = false;
      state.fullAppLoading = false;
    },
    setFullAppLoading(state, action: PayloadAction<boolean>) {
      state.fullAppLoading = action.payload;
    },
    setBaseContainerLoading(state, action: PayloadAction<boolean>) {
      state.baseContainerLoading = action.payload;
    },
  },
});

export const { setBaseContainerLoading } = loadingSlice.actions;
export const loadingReducer = loadingSlice.reducer;
