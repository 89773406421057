import { call, put } from 'redux-saga/effects';
import {
  endMastersWorker,
  setData,
  setError,
  startMastersWorker,
} from '../../state/masters/mastersList/slice';
import { EmployersAPI } from '../../api/employers';
import { message } from 'antd';

export function* loadAllWorker(): Generator {
  try {
    yield put(startMastersWorker());
    const response: any = yield call(EmployersAPI.getAll);

    yield put(setData(response.data));
    yield put(endMastersWorker());
  } catch (e) {
    yield put(setError());
    yield put(endMastersWorker());
    yield call(message.error, 'Ошибка при загрузке списка мастеров');
  }
}
