import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IShortedSalon } from '../../../types/salon';

export interface IActionItem {
  id: string;
  title: string;
  terms: string;
  thumbs: string;
  fullImage: string;
  description: string;
  startDate: string;
  endDate: string;
  serviceStatus: number;
  status: 'Включена';
  salon: IShortedSalon;
}

export type IActionFromSalon = Omit<IActionItem, 'salon'>;

type TState = {
  isPending: boolean;
  isLoaded: boolean;
  isError: boolean;
  data: IActionItem[];
};

const initialState: TState = {
  isPending: false,
  isError: false,
  data: [],
  isLoaded: false,
};

const actionsListSlice = createSlice({
  name: 'actions/list',
  initialState: initialState as TState,
  reducers: {
    startActionsListWorker(state) {
      state.isPending = true;
    },
    endActionsListWorker(state) {
      state.isPending = false;
    },
    setActionsData(state, action: PayloadAction<IActionItem[]>) {
      state.data = action.payload;
      state.isLoaded = true;
      state.isError = false;
    },
    setActionsError(state) {
      state.isError = true;
    },
    addNewAction(state, action: PayloadAction<IActionItem>) {
      state.data = [...state.data, action.payload];
    },
    deleteAction(state, action: PayloadAction<string>) {
      state.data = [...state.data.filter((el) => el.id !== action.payload)];
    },
  },
});

export const {
  startActionsListWorker,
  endActionsListWorker,
  setActionsError,
  setActionsData,
  addNewAction,
  deleteAction,
} = actionsListSlice.actions;

export const actionsListReducer = actionsListSlice.reducer;
