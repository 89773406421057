import { Role } from './roles';
import { IMedia, Nullable } from './common';
import { ISalon } from './salon';

export enum IPartStatus {
  APPROVED = 'Подтвержден',
  UNAPPROVED = 'Не подтвержден',
}

export enum IProfileStatus {
  ACTIVE = 'Активные',
  INACTIVE = 'Отключенный',
}

export interface IBaseProfile {
  id: string | number;
  createAt: string;
  updateAt: string;
  isEdit: boolean;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  patronymic: Nullable<string>;
  birthDate: Nullable<string>;
  phone: string;
  email: Nullable<string>;
  city: Nullable<string>;
  role: Role;
  rating: number;
  king: 'FEMALE' | 'MALE';
  emailStatus: IPartStatus;
  phoneStatus: IPartStatus;
  status: IProfileStatus;
}

export interface IClient extends IBaseProfile {
  role: Role.CLIENT | Role;
}

export interface ICustomer extends IBaseProfile {
  role: Role.CUSTOMER | Role;
}

export interface IMaster extends IBaseProfile {
  role: Role.MASTER | Role;
}

export interface ISelfEmployer extends IBaseProfile {
  role: Role.SELF_EMPLOYER | Role;
}

export interface IProfile extends IBaseProfile {
  avatar: IMedia | null;
  media: IMedia[];
  salons: ISalon[];
}
