import { call, put } from 'redux-saga/effects';
import {
  endActionsListWorker,
  setActionsData,
  setActionsError,
  startActionsListWorker,
} from '../../../state/home-settings/actionsList/slice';
import { HomeSettingsAPI } from '../../../api/home-settings';

export function* actionsAllLoadWorker(): Generator {
  try {
    yield put(startActionsListWorker());

    const response: any = yield call(HomeSettingsAPI.getAllActions);

    yield put(setActionsData(response.data.actions));

    yield put(endActionsListWorker());
  } catch (e) {
    yield put(setActionsError());
    yield put(endActionsListWorker());
  }
}
