import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Drawer, Form, message, Popconfirm, Space, Tabs } from 'antd';
import styled from '@emotion/styled';
import { useCurrentSalon } from '../../state/salons/currentSalon/selectors';
import { LoadingOutlined } from '@ant-design/icons';
import { SalonDataForm } from './salon-data-form';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch } from '../../state';
import {
  deleteSalonAction,
  editSalonMainDataByIdAction,
} from '../../state/salons/currentSalon/actions';
import { useCustomersListSelector } from '../../state/customers/customersList/selectors';
import { loadAllSalonsAction } from '../../state/salons/salonsList/actions';
import { SalonActionsList } from './salon-actions-list';
import { ActionDrawer } from '../home-settings/action-drawer';
import { IActionFromSalon } from '../../state/home-settings/actionsList/slice';
import { useSalonsListSelector } from '../../state/salons/salonsList/selectors';
import { useSESelector } from '../../state/self-employers/list/selectors';
import { SalonMastersList } from './salon-masters-list';

export type ISalonForm = {
  title: string;
  description?: string;
  email?: string;
  phone?: string;
  city?: string;
  address?: string;
  metro?: string;
  latitude: number;
  longitude: number;
  rating: number;
  startJobTime?: Dayjs;
  endJobTime?: Dayjs;
  website?: string;
  social?: string;
  ownerId?: string;
};

export type ISalonEdited = {
  title: string;
  description?: string;
  email?: string;
  phone?: string;
  city?: string;
  address?: string;
  metro?: string;
  latitude: number;
  longitude: number;
  rating: number;
  startJobTime?: string;
  endJobTime?: string;
  website?: string;
  social?: string;
};

type TProps = {
  open: boolean;
  onClose: () => void;
};

export const DrawerContent = styled('div')<{ isPending: boolean }>`
  width: 100%;
  height: 100%;
  display: ${({ isPending }) => (isPending ? 'flex' : 'block')};
  align-items: ${({ isPending }) => (isPending ? 'center' : 'unset')};
  justify-content: ${({ isPending }) => (isPending ? 'center' : 'unset')};
  font-size: ${({ isPending }) => (isPending ? '64px' : 'unset')};
  color: ${({ isPending, theme }) =>
    isPending ? theme.COLORS.ACCENT.PRIMARY : 'unset'};
`;

export const EditSalonDrawer: FC<TProps> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const salonsListData = useSalonsListSelector();
  const { isPending, data, isLoaded } = useCurrentSalon();
  const customersData = useCustomersListSelector();
  const selfEmployersData = useSESelector();
  const [form] = Form.useForm();

  const [activeTab, setActiveTab] = useState<string>('main-form');

  const [selectedAction, setSelectedAction] = useState<
    IActionFromSalon | undefined
  >(undefined);

  const [drawerActionCreateOpen, setDrawerActionCreateOpen] = useState(false);
  const [drawerActionEditOpen, setDrawerActionEditOpen] = useState(false);

  const handleCloseActionCreate = useCallback(() => {
    setDrawerActionCreateOpen(false);
  }, []);

  const handleOpenActionCreate = useCallback(() => {
    setDrawerActionCreateOpen(true);
  }, []);

  const handleCloseActionEdit = useCallback(() => {
    setDrawerActionEditOpen(false);
  }, []);

  const handleEditAction = useCallback((act: IActionFromSalon) => {
    setSelectedAction(() => act);
    setDrawerActionEditOpen(() => true);
  }, []);

  const onEditMainFinish = useCallback(
    (values: ISalonForm) => {
      dispatch(
        editSalonMainDataByIdAction({
          salonId: data?.id ?? '',
          newData: {
            ...values,
            startJobTime: values.startJobTime?.format('HH:mm'),
            endJobTime: values.endJobTime?.format('HH:mm'),
          },
          successCallBack: () => {
            onClose();
            void message.success('Данные салона изменены');
            dispatch(loadAllSalonsAction());
          },
        })
      );
    },
    [data]
  );

  useEffect(() => {
    if (data && !isPending && isLoaded) {
      form.setFields([
        {
          name: 'title',
          value: data.title,
        },
        {
          name: 'description',
          value: data.description,
        },
        {
          name: 'email',
          value: data.email,
        },
        {
          name: 'phone',
          value: data.phone,
        },
        {
          name: 'city',
          value: data.city,
        },
        {
          name: 'address',
          value: data.address,
        },
        {
          name: 'metro',
          value: data.metro,
        },
        {
          name: 'latitude',
          value: data.latitude,
        },
        {
          name: 'longitude',
          value: data.longitude,
        },
        {
          name: 'rating',
          value: data.rating,
        },
        {
          name: 'startJobTime',
          value: dayjs()
            .set('hours', Number(data.startJobTime?.split(':')[0]))
            .set('minutes', Number(data.startJobTime?.split(':')[1])),
        },
        {
          name: 'endJobTime',
          value: dayjs()
            .set('hours', Number(data.endJobTime?.split(':')[0]))
            .set('minutes', Number(data.endJobTime?.split(':')[1])),
        },
        {
          name: 'website',
          value: data.website,
        },
        {
          name: 'social',
          value: data.social,
        },
        {
          name: 'ownerId',
          value: data.owner?.id,
        },
      ]);
    }
  }, [data, isPending, isLoaded]);

  const handleDeleteSalon = useCallback(() => {
    if (data && data.id !== undefined) {
      const loader = message.loading('Удаление салона', 0);
      dispatch(
        deleteSalonAction({
          salonId: data.id,
          successCallBack: () => {
            onClose();
            loader();
            void message.success('Салон успешно удален');
          },
          errorCallBack: () => {
            loader();
            void message.error('Ошибка при удалении салона');
          },
        })
      );
    }
  }, [data]);

  return (
    <Drawer
      title={'Редактирование салона'}
      width={700}
      onClose={onClose}
      closable={false}
      maskClosable={false}
      open={open}
      extra={
        <Space>
          <Popconfirm
            title="Вы уверены?"
            description={`Вы точно хотите удалить салон "${data?.title}"`}
            okText="Да, удалить"
            cancelText="Отмена"
            okButtonProps={{
              danger: true,
            }}
            onConfirm={handleDeleteSalon}
          >
            <Button danger type="primary">
              Удалить салон
            </Button>
          </Popconfirm>
          <Button onClick={onClose}>Отмена</Button>
          <Button type="primary" onClick={form.submit}>
            Применить
          </Button>
        </Space>
      }
    >
      <DrawerContent isPending={isPending}>
        {isPending ||
        customersData.isPending ||
        salonsListData.isPending ||
        selfEmployersData.isPending ? (
          <LoadingOutlined />
        ) : (
          <>
            <Tabs
              activeKey={activeTab}
              onChange={setActiveTab}
              items={[
                {
                  key: 'main-form',
                  label: 'Основная информация',
                  children: (
                    <SalonDataForm
                      mode="edit"
                      customers={[
                        ...(customersData.customers ?? []),
                        ...(selfEmployersData.selfEmployers ?? []),
                      ]}
                      form={form}
                      onFinish={onEditMainFinish}
                    />
                  ),
                },
                {
                  key: 'actions',
                  label: 'Акции',
                  children: (
                    <SalonActionsList
                      handleOpenActionCreate={handleOpenActionCreate}
                      handleEditAction={handleEditAction}
                      actions={data?.actions}
                      salonId={data?.id as string}
                    />
                  ),
                },
                {
                  key: 'masters',
                  label: 'Мастера',
                  children: (
                    <SalonMastersList
                      salonId={data?.id as string}
                      employers={data?.employers}
                      services={data?.services}
                    />
                  ),
                },
              ]}
            />

            <ActionDrawer
              mode={'create'}
              isOpen={drawerActionCreateOpen}
              currentAction={undefined}
              onClose={handleCloseActionCreate}
              salonId={data?.id as string}
            />

            <ActionDrawer
              mode={'edit'}
              isOpen={drawerActionEditOpen}
              currentAction={selectedAction}
              onClose={handleCloseActionEdit}
              salonId={data?.id as string}
            />
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
};
