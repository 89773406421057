import { AxiosResponse } from 'axios';
import { ISelfEmployer } from '../../types/profile';
import { baseAPIInstance } from '../index';

interface IApi {
  loadAll: () => Promise<AxiosResponse<ISelfEmployer[]>>;
  editSelfEmployer: (
    data: Partial<ISelfEmployer>,
    id: string | number
  ) => Promise<AxiosResponse>;
}

export const SelfEmployersAPI: IApi = {
  loadAll: () => {
    return baseAPIInstance.get('/account');
  },
  editSelfEmployer: async (data, id) => {
    return baseAPIInstance.patch(`/account/${id}`, data);
  },
};
