import { deleteActionAction } from '../../../state/home-settings/actionsList/actions';
import { call, put } from 'redux-saga/effects';
import {
  deleteAction,
  endActionsListWorker,
  setActionsError,
  startActionsListWorker,
} from '../../../state/home-settings/actionsList/slice';
import { HomeSettingsAPI } from '../../../api/home-settings';

export function* deleteActionWorker(
  action: ReturnType<typeof deleteActionAction>
): Generator {
  try {
    yield put(startActionsListWorker());

    yield call(HomeSettingsAPI.deleteAction, action.payload.id);
    yield put(deleteAction(action.payload.id));

    yield put(endActionsListWorker());
    if (action.payload.successCallBack) {
      yield call(action.payload.successCallBack);
    }
  } catch (e) {
    yield put(setActionsError());
    yield put(endActionsListWorker());
    if (action.payload.errorCallBack) {
      yield call(action.payload.errorCallBack);
    }
  }
}
