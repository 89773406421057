import { call, put } from 'redux-saga/effects';
import {
  editClient,
  endClientsWorker,
  startClientsWorker,
} from '../../state/clients/clientsList/slice';
import { ClientsAPI } from '../../api/clients';
import { editClientAction } from '../../state/clients/clientsList/actions';
import { message } from 'antd';
import { Role } from '../../types/roles';
import { setNeedSELoad } from '../../state/self-employers/list/slice';
import { setNeedMastersLoad } from '../../state/masters/mastersList/slice';
import { setNeedCustomersLoad } from '../../state/customers/customersList/slice';

export function* editWorker(
  action: ReturnType<typeof editClientAction>
): Generator {
  try {
    yield put(startClientsWorker());

    yield call(
      ClientsAPI.editClient,
      action.payload.newValues,
      action.payload.client.id
    );

    yield put(editClient(action.payload.client));

    if (action.payload.newValues.role !== Role.CLIENT) {
      switch (action.payload.newValues.role) {
        case Role.SELF_EMPLOYER:
          yield put(setNeedSELoad());
          break;
        case Role.MASTER:
          yield put(setNeedMastersLoad());
          break;
        case Role.CUSTOMER:
          yield put(setNeedCustomersLoad());
          break;
      }
    }

    yield put(endClientsWorker());
    yield call(action.payload.successCallback);
    yield call(message.success, 'Клиент успешно изменен');
  } catch (e) {
    yield put(endClientsWorker());
    yield call(message.error, 'Ошибка изменения клиента');
  }
}
