import React, { FC, useCallback } from 'react';
import { IActionFromSalon } from '../../state/home-settings/actionsList/slice';
import styled from '@emotion/styled';
import { Button, Card, Col, Image, message, Row, Space, Tooltip } from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { deleteActionAction } from '../../state/home-settings/actionsList/actions';
import { useAppDispatch } from '../../state';
import { loadSalonByIdAction } from '../../state/salons/currentSalon/actions';

interface Props {
  actions?: IActionFromSalon[];
  handleOpenActionCreate: () => void;
  handleEditAction: (act: IActionFromSalon) => void;
  salonId: string | number;
}

const { Meta } = Card;

const Wrapper = styled('div')`
  width: 100%;
`;

const TitleWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AddCard = styled(Card)<{ height?: number }>`
  border-style: dashed;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => (height ? `${height}px` : '366px')};

  .ant-card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  &:hover {
    border: 1px dashed ${({ theme }) => theme.COLORS.ACCENT.PRIMARY};
  }
`;

export const SalonActionsList: FC<Props> = ({
  actions,
  handleOpenActionCreate,
  handleEditAction,
  salonId,
}) => {
  const dispatch = useAppDispatch();

  const handleDeleteAction = useCallback(
    (act: IActionFromSalon) => {
      const loading = message.loading('Удаление', 0);

      dispatch(
        deleteActionAction({
          id: act.id,
          errorCallBack: () => {
            loading();
            void message.error('Ошибка при удалении акции');
          },
          successCallBack: () => {
            loading();
            dispatch(
              loadSalonByIdAction({
                id: salonId,
              })
            );
            void message.success('Акция удалена');
          },
        })
      );
    },
    [salonId]
  );

  const handleCopyId = useCallback((actId: string | number) => {
    navigator.clipboard.writeText(String(actId)).then(() => {
      void message.success('ID скопирован');
    });
  }, []);

  return (
    <Wrapper>
      <Row wrap={true} gutter={[8, 8]}>
        {actions?.map((act) => (
          <Col span={8} key={act.id}>
            <Card
              cover={
                <Image
                  alt={act.thumbs}
                  src={act.thumbs}
                  height={260}
                  style={{
                    objectFit: 'cover',
                  }}
                  preview={{
                    src: act.fullImage,
                  }}
                />
              }
            >
              <Meta
                title={
                  <TitleWrapper>
                    <span>{act.title}</span>
                    <Space size={8} wrap={true}>
                      <Tooltip title="Скопировать ID акции">
                        <Button
                          shape={'circle'}
                          onClick={() => {
                            handleCopyId(act.id);
                          }}
                        >
                          <CopyOutlined />
                        </Button>
                      </Tooltip>
                      <Button
                        shape={'circle'}
                        onClick={() => {
                          handleEditAction(act);
                        }}
                      >
                        <EditOutlined />
                      </Button>
                      <Button
                        danger
                        type="primary"
                        shape={'circle'}
                        onClick={() => {
                          handleDeleteAction(act);
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Space>
                  </TitleWrapper>
                }
              />
            </Card>
          </Col>
        ))}
        <Col span={8}>
          <AddCard onClick={handleOpenActionCreate}>
            <PlusOutlined />
            Создать
          </AddCard>
        </Col>
      </Row>
    </Wrapper>
  );
};
