import React, { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Paths } from './paths';
import { Home } from '../../pages/home';
import { Login } from '../../pages/login';
import { MainLayout } from '../../components/layouts/MainLayout';
import { Clients } from '../../pages/clients';
import { Customers } from '../../pages/customers';
import { Masters } from '../../pages/masters';
import { Salons } from '../../pages/salons';
import { HomeSettings } from '../../pages/home-settings';
import { SelfEmployers } from '../../pages/self-employers';

export const RootRoutes: FC = () => {
  return (
    <Routes>
      <Route path={Paths.BASE} element={<MainLayout />}>
        <Route path={Paths.BASE} element={<Home />} />
        <Route path={Paths.CLIENTS} element={<Clients />} />
        <Route path={Paths.CUSTOMERS} element={<Customers />} />
        <Route path={Paths.MASTERS} element={<Masters />} />
        <Route path={Paths.SALONS} element={<Salons />} />
        <Route path={Paths.HOME_SETTINGS} element={<HomeSettings />} />
        <Route path={Paths.SELF_EMPLOYERS} element={<SelfEmployers />} />
      </Route>
      <Route path={Paths.LOGIN} element={<Login />} />
    </Routes>
  );
};
