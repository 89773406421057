import { call, put } from 'redux-saga/effects';
import {
  startSEWorker,
  endSEWorker,
  setSEData,
  setError,
} from '../../state/self-employers/list/slice';
import { SelfEmployersAPI } from '../../api/self-employers';

export function* loadAllWorker(): Generator {
  try {
    yield put(startSEWorker());
    const response: any = yield call(SelfEmployersAPI.loadAll);

    yield put(setSEData(response.data));

    yield put(endSEWorker());
  } catch (e) {
    yield put(setError());
    yield put(endSEWorker());
  }
}
