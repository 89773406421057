import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Menu, MenuProps } from 'antd';
import {
  FormatPainterOutlined,
  HomeOutlined,
  SettingOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from '../../utils/routes/paths';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  onClick?: () => void,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    onClick,
  } as MenuItem;
}

export const LayoutMenu: FC = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const handleNavigate = useCallback((path: Paths) => {
    navigate(path);
  }, []);

  const [selectedKey, setSelectedKey] = useState<Paths[]>([
    location.pathname as Paths,
  ]);

  const items: MenuItem[] = useMemo(
    () => [
      getItem(
        'Главная',
        Paths.BASE,
        () => {
          handleNavigate(Paths.BASE);
        },
        <HomeOutlined />
      ),
      getItem(
        'Клиенты',
        Paths.CLIENTS,
        () => {
          handleNavigate(Paths.CLIENTS);
        },
        <TeamOutlined />
      ),
      getItem(
        'Кастомеры',
        Paths.CUSTOMERS,
        () => {
          handleNavigate(Paths.CUSTOMERS);
        },
        <UserOutlined />
      ),
      getItem(
        'Мастера',
        Paths.MASTERS,
        () => {
          handleNavigate(Paths.MASTERS);
        },
        <FormatPainterOutlined />
      ),
      getItem(
        'Салоны',
        Paths.SALONS,
        () => {
          handleNavigate(Paths.SALONS);
        },
        <ShopOutlined />
      ),
      getItem(
        'Настройки главной',
        Paths.HOME_SETTINGS,
        () => {
          handleNavigate(Paths.HOME_SETTINGS);
        },
        <SettingOutlined />
      ),
      getItem(
        'Самозанятые',
        Paths.SELF_EMPLOYERS,
        () => {
          handleNavigate(Paths.SELF_EMPLOYERS);
        },
        <UserSwitchOutlined />
      ),
    ],
    []
  );

  useEffect(() => {
    const pathName: Paths = location.pathname as Paths;

    setSelectedKey([pathName]);
  }, [location]);

  return (
    <Menu
      theme={'dark'}
      defaultSelectedKeys={[Paths.BASE]}
      selectedKeys={selectedKey}
      mode="inline"
      items={items}
    />
  );
};
