import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import logo from '../assets/img/logo.png';
import { Button, Form, Input } from 'antd';
import { useAppDispatch } from '../state';
import { loginAction } from '../state/auth/actions';
import { useNavigate } from 'react-router-dom';
import { Paths } from '../utils/routes/paths';
import { useAuthSelector } from '../state/auth/selectors';

const Wrapper = styled('div')`
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

const LoginWrapper = styled('div')`
  width: 320px;
  height: 100vh;
  display: flex;
  align-items: center;
`;

const LoginInner = styled('div')`
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
`;

const Logo = styled('div')`
  width: 200px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  img {
    object-fit: cover;
    width: 100%;
  }
`;

interface ILoginForm {
  login: string;
  password: string;
}

export const Login: FC = () => {
  const { authed, data } = useAuthSelector();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<ILoginForm>();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(() => {
    form.submit();
  }, []);

  const onFormFinish = useCallback((values: ILoginForm) => {
    const commonActions = () => {
      setIsLoading(() => false);
    };

    setIsLoading(true);

    dispatch(
      loginAction({
        publicKey: values.login,
        secretKey: values.password,
        loginErrorCallBack: () => {
          commonActions();
        },
        loginSuccessCallBack: () => {
          commonActions();
        },
      })
    );
  }, []);

  useEffect(() => {
    if (authed && data?.accessToken) {
      navigate(Paths.BASE);
    }
  }, [authed, data]);

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleSubmit();
      }
    };

    window.addEventListener('keypress', listener);

    return () => {
      window.removeEventListener('keypress', listener);
    };
  }, []);

  return (
    <Wrapper>
      <LoginWrapper>
        <LoginInner>
          <Logo>
            <img src={logo} alt="Site logo" />
          </Logo>
          <Form form={form} layout={'vertical'} onFinish={onFormFinish}>
            <Form.Item
              name={'login'}
              label={'Номер телефона'}
              rules={[
                {
                  required: true,
                  message: 'Поле обязательно для заполнения',
                },
              ]}
              tooltip={'Номер телефона начиная с 7'}
              requiredMark={false}
            >
              <Input placeholder={'Логин'} />
            </Form.Item>
            <Form.Item
              name={'password'}
              label={'Пароль'}
              requiredMark={false}
              rules={[
                {
                  required: true,
                  message: 'Поле обязательно для заполнения',
                },
              ]}
            >
              <Input.Password placeholder={'Пароль'} />
            </Form.Item>
          </Form>
          <Button
            type={'primary'}
            onClick={handleSubmit}
            loading={isLoading}
            disabled={isLoading}
          >
            Войти
          </Button>
        </LoginInner>
      </LoginWrapper>
    </Wrapper>
  );
};
