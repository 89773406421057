import { call, put, takeLeading } from 'redux-saga/effects';
import {
  endMeWorker,
  setData,
  setError,
  startMeWorker,
} from '../../state/profile/slice';
import { message } from 'antd';
import { ProfileAPI } from '../../api/profile';
import { authMeAction } from '../../state/profile/actions';

function* profileWorker(): Generator {
  try {
    yield put(startMeWorker());
    const response: any = yield call(ProfileAPI.me);

    yield put(setData(response.data));
    yield put(endMeWorker());
  } catch (e) {
    yield put(setError());
    yield put(endMeWorker());
    void message.error('Ошибка при получении данных пользователя');
  }
}

export function* profileSaga(): Generator {
  yield takeLeading(authMeAction, profileWorker);
}
