import { CustomersAPI } from '../../api/customers';
import { call, put } from 'redux-saga/effects';
import {
  endCustomersWorker,
  setData,
  setError,
  startCustomersLoadWorker,
} from '../../state/customers/customersList/slice';
import { message } from 'antd';

export function* loadAllWorker(): Generator {
  try {
    yield put(startCustomersLoadWorker());
    const response: any = yield call(CustomersAPI.getAll);

    yield put(setData(response.data));
    yield put(endCustomersWorker());
  } catch (e) {
    yield put(setError());
    yield put(endCustomersWorker());
    yield call(message.error, 'Ошибка при загрузке списка кастомеров');
  }
}
