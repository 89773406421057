import React, { FC, useEffect, useState } from 'react';
import { Button, Form, FormInstance, Image, Input, Upload } from 'antd';
import { IBannerForm } from './banner-drawer';
import styled from '@emotion/styled';
import { UploadOutlined } from '@ant-design/icons';

interface Props {
  form: FormInstance<IBannerForm>;
  defaultFileUrl?: string;
  handleFinishForm: (values: IBannerForm) => void;
}

export const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const FormWrapper = styled('div')`
  width: 100%;
`;

export const BannerCeForm: FC<Props> = ({
  form,
  defaultFileUrl,
  handleFinishForm,
}) => {
  const [fileList, setFileList] = useState<File[]>([]);
  const [fileUrl, setFileUrl] = useState<string | undefined>(defaultFileUrl);

  useEffect(() => {
    if (defaultFileUrl) {
      setFileUrl(defaultFileUrl);
    }
  }, [defaultFileUrl]);

  useEffect(() => {
    if (fileList.length) {
      const fr = new FileReader();

      fr.onload = () => {
        setFileUrl(fr.result as string);
      };

      fr.readAsDataURL(fileList[0]);
    } else {
      if (defaultFileUrl) {
        setFileUrl(defaultFileUrl);
      } else {
        setFileUrl(undefined);
      }
    }
  }, [fileList]);

  return (
    <FormWrapper>
      <Form form={form} onFinish={handleFinishForm} layout={'vertical'}>
        <Form.Item
          name="mediaList"
          label="Файл"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            beforeUpload={(file) => {
              return new Promise((res) => {
                res(file);
              });
            }}
            customRequest={(e) => {
              setFileList([e.file as File]);
            }}
            showUploadList={false}
            accept={'.jpg, .jpeg, .png'}
          >
            <Button icon={<UploadOutlined />}>Выберите файл</Button>
          </Upload>
        </Form.Item>
        <div>
          {fileUrl && <Image width={210} height={30} src={fileUrl} />}
          {fileList[0] ? fileList[0].name : ''}
        </div>
        <Form.Item
          name="url"
          label="Ссылка"
          rules={[
            {
              required: true,
              message: 'Поле обязательно',
            },
          ]}
        >
          <Input placeholder={'Введите'} />
        </Form.Item>
      </Form>
    </FormWrapper>
  );
};
