import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISalon } from '../../../types/salon';
import { ISalonEdited } from '../../../components/salons/edit-salon-drawer';

type TState =
  | {
      isLoaded: true;
      isPending: boolean;
      isError: boolean;
      data: Partial<ISalon>;
    }
  | {
      isLoaded: false;
      isPending: boolean;
      isError: boolean;
      data: undefined;
    };

const initialState: TState = {
  isLoaded: false,
  isError: false,
  isPending: false,
  data: undefined,
};

const currentSalonSlice = createSlice({
  name: 'salon/current',
  initialState: initialState as TState,
  reducers: {
    startSalonLoadWorker(state) {
      state.isPending = true;
    },
    endSalonLoadWorker(state) {
      state.isPending = false;
    },
    setData(state, action: PayloadAction<ISalon>) {
      state.isLoaded = true;
      state.data = action.payload;
    },
    setError(state) {
      state.isLoaded = false;
      state.isError = true;
    },
    editSalon(
      state,
      action: PayloadAction<{
        data: ISalonEdited;
        id: string | number;
      }>
    ) {
      if (state.data?.id === action.payload.id) {
        state.data = {
          ...state.data,
          ...action.payload.data,
        };
      }
    },
  },
});

export const {
  startSalonLoadWorker,
  endSalonLoadWorker,
  setData,
  setError,
  editSalon,
} = currentSalonSlice.actions;
export const currentSalonReducer = currentSalonSlice.reducer;
