import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IActionItem } from '../actionsList/slice';

export interface IGeneratedAction {
  id: string;
  sort: number;
  actions: [IActionItem];
}

interface IState {
  isPending: boolean;
  isLoaded: boolean;
  isError: boolean;
  data: IGeneratedAction[];
}

const initialState: IState = {
  isPending: false,
  isError: false,
  data: [],
  isLoaded: false,
};

const actionsGeneralSlice = createSlice({
  name: 'actions/general/list',
  initialState: initialState as IState,
  reducers: {
    startActionsGeneralListWorker(state) {
      state.isPending = true;
    },
    endActionsGeneralListWorker(state) {
      state.isPending = false;
    },
    setActionsGeneralData(state, action: PayloadAction<IGeneratedAction[]>) {
      state.data = action.payload;
      state.isLoaded = true;
      state.isError = false;
    },
    setActionsGeneralError(state) {
      state.isError = true;
    },
  },
});

export const {
  startActionsGeneralListWorker,
  endActionsGeneralListWorker,
  setActionsGeneralData,
  setActionsGeneralError,
} = actionsGeneralSlice.actions;
export const actionsGeneralReducer = actionsGeneralSlice.reducer;
