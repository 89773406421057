import { IBaseProfile } from '../../types/profile';
import moment from 'moment/moment';
import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React from 'react';

export const baseProfileColumns = [
  {
    title: '№',
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: 'Имя',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Номер телефона',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Город',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Рейтинг',
    dataIndex: 'rating',
    key: 'rating',
  },
  {
    title: 'Дата рождения',
    dataIndex: 'birthDate',
    key: 'birthDate',
  },
  {
    title: 'Статус',
    dataIndex: 'status',
    key: 'status',
  },
  {
    title: 'Дата регистрации',
    dataIndex: 'createAt',
    key: 'createAt',
  },
  {
    title: 'Последнее изменение',
    dataIndex: 'updateAt',
    key: 'updateAt',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];

export const mapBaseProfileToTable: (
  item: IBaseProfile,
  index: number,
  clickHandler: (item: IBaseProfile) => void
) => any = (el, index, clickHandler) => ({
  key: el.id,
  number: index + 1,
  name: el.firstName
    ? `${el.lastName ?? ''} ${el.firstName ?? ''} ${el.patronymic ?? ''}`
    : 'Не указано',
  phone: el.phone ? `${el.phone} | ${el.phoneStatus}` : 'Не указан',
  email: el.email ? `${el.email} | ${el.emailStatus}` : 'Не указан',
  city: el.city,
  rating: el.rating?.toLocaleString() ?? 'Не рассчитан',
  birthDate: el.birthDate
    ? `${moment(el.birthDate).format('DD.MM.YYYY')} | ${moment().diff(
        moment(el.birthDate),
        'years'
      )}`
    : 'Не указана',
  status: el.status,
  createAt: moment(el.createAt).format('DD.MM.YYYY'),
  updateAt: moment(el.updateAt).format('DD.MM.YYYY'),
  actions: (
    <Button
      shape={'circle'}
      onClick={() => {
        clickHandler(el);
      }}
    >
      <EditOutlined />
    </Button>
  ),
});
