import { createAction } from '@reduxjs/toolkit';
import { ISalonEdited } from '../../../components/salons/edit-salon-drawer';

export const loadSalonByIdAction = createAction<{
  id: string | number;
  errorCallBack?: () => void;
  successCallBack?: () => void;
}>('salon/get/by-id');
export const editSalonMainDataByIdAction = createAction<{
  newData: ISalonEdited;
  salonId: string | number;
  errorCallBack?: () => void;
  successCallBack?: () => void;
}>('salon/current/edit');
export const createSalonAction = createAction<{
  newData: ISalonEdited;
  errorCallBack?: () => void;
  successCallBack?: () => void;
}>('salon/create');
export const deleteSalonAction = createAction<{
  salonId: string | number;
  errorCallBack?: () => void;
  successCallBack?: () => void;
}>('salon/delete');
