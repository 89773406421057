import { deleteBannerAction } from '../../../state/home-settings/banners/actions';
import { call, put } from 'redux-saga/effects';
import {
  deleteBanner,
  endBannersWorker,
  startBannersWorker,
} from '../../../state/home-settings/banners/slice';
import { HomeSettingsAPI } from '../../../api/home-settings';
import { CommonAPI } from '../../../api/common';

export function* deleteBannerWorker(
  action: ReturnType<typeof deleteBannerAction>
): Generator {
  try {
    yield put(startBannersWorker());

    yield call(CommonAPI.deleteFile, action.payload.mediaId);
    yield call(HomeSettingsAPI.deleteBanner, action.payload.bannerId);

    yield put(deleteBanner(action.payload.bannerId));

    yield put(endBannersWorker());
    if (action.payload.successCallBack) {
      yield call(action.payload.successCallBack);
    }
  } catch (e) {
    yield put(endBannersWorker());
    if (action.payload.errorCallBack) {
      yield call(action.payload.errorCallBack);
    }
  }
}
