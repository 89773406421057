import { takeLeading } from 'redux-saga/effects';
import {
  createBannerAction,
  deleteBannerAction,
  editBannerAction,
  loadAllBannersAction,
} from '../../../state/home-settings/banners/actions';
import { bannersAllLoadWorker } from './bannersAllLoadWorker';
import { createBannerWorker } from './createBannerWorker';
import { deleteBannerWorker } from './deleteBannerWorker';
import { editBannerWorker } from './editBannerWorker';

export function* bannersSaga(): Generator {
  yield takeLeading(loadAllBannersAction, bannersAllLoadWorker);
  yield takeLeading(createBannerAction, createBannerWorker);
  yield takeLeading(deleteBannerAction, deleteBannerWorker);
  yield takeLeading(editBannerAction, editBannerWorker);
}
