import { loadSalonByIdAction } from '../../state/salons/currentSalon/actions';
import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import {
  startSalonLoadWorker,
  endSalonLoadWorker,
  setData,
  setError,
} from '../../state/salons/currentSalon/slice';
import { SalonAPI } from '../../api/salon';

export function* loadCurrentWorker(
  action: ReturnType<typeof loadSalonByIdAction>
): Generator {
  try {
    yield put(startSalonLoadWorker());
    const response: any = yield call(SalonAPI.getById, action.payload.id);

    yield put(setData(response.data));

    yield put(endSalonLoadWorker());
    if (action.payload.successCallBack) {
      yield call(action.payload.successCallBack);
    }
  } catch (e) {
    yield put(setError());
    yield put(endSalonLoadWorker());
    if (action.payload.errorCallBack) {
      yield call(action.payload.errorCallBack);
    }
    yield call(message.error, 'Ошибка при загрузке салона');
  }
}
