import { createActionAction } from '../../../state/home-settings/actionsList/actions';
import { call, delay, put } from 'redux-saga/effects';
import {
  addNewAction,
  endActionsListWorker,
  setActionsError,
  startActionsListWorker,
} from '../../../state/home-settings/actionsList/slice';
import { HomeSettingsAPI } from '../../../api/home-settings';
import { CommonAPI, IMediaType } from '../../../api/common';

export const handleUploadActionFiles = async (thumb: File, full: File) =>
  Promise.all([
    CommonAPI.uploadFile(IMediaType.BANNER, thumb),
    CommonAPI.uploadFile(IMediaType.BANNER, full),
  ]);

export function* createActionWorker(
  action: ReturnType<typeof createActionAction>
): Generator {
  try {
    yield put(startActionsListWorker());

    const filesResponse: any = yield call(
      handleUploadActionFiles,
      action.payload.data.thumbs,
      action.payload.data.fullImage
    );

    yield delay(1000);

    const response: any = yield call(HomeSettingsAPI.createAction, {
      ...action.payload.data,
      thumbs: filesResponse[0].data.url,
      fullImage: filesResponse[1].data.url,
    });

    yield put(addNewAction(response.data));

    yield put(endActionsListWorker());
    if (action.payload.successCallBack) {
      yield call(action.payload.successCallBack);
    }
  } catch (e) {
    yield put(setActionsError());
    yield put(endActionsListWorker());
    if (action.payload.errorCallBack) {
      yield call(action.payload.errorCallBack);
    }
  }
}
