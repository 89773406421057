import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Wrapper } from './clients';
import { Button, Space, Typography, Image, message, Card } from 'antd';
import styled from '@emotion/styled';
import { useBannersSelector } from '../state/home-settings/banners/selectors';
import { useAppDispatch } from '../state';
import {
  deleteBannerAction,
  loadAllBannersAction,
} from '../state/home-settings/banners/actions';
import { getMediaFilePath } from '../utils/common';
import { Col, Row } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { BannerDrawer } from '../components/home-settings/banner-drawer';
import { IBanner } from '../state/home-settings/banners/slice';
import { useSalonsListSelector } from '../state/salons/salonsList/selectors';
import { loadAllSalonsAction } from '../state/salons/salonsList/actions';
import { useGeneralActionsList } from '../state/home-settings/actionsGeneral/selectors';
import { loadAllActionsGeneralAction } from '../state/home-settings/actionsGeneral/actions';
import { GeneralActionModal } from '../components/home-settings/general-action-modal';
import { ActionItemCol } from '../components/home-settings/action-item-col';
import { IActionItem } from '../state/home-settings/actionsList/slice';

const { Meta } = Card;

const BannersWrapper = styled('div')`
  border-radius: 4px;
  padding: 15px 0;
`;

const TitleWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const HomeSettings: FC = () => {
  const dispatch = useAppDispatch();

  const { loaded, loading, data, error } = useBannersSelector();

  const actionsData = useGeneralActionsList();

  const salonsData = useSalonsListSelector();

  useEffect(() => {
    if (!salonsData.isPending && !salonsData.isError && !salonsData.isLoaded) {
      dispatch(loadAllSalonsAction());
    }
  }, [salonsData.isLoaded, salonsData.isPending, salonsData.isError]);

  useEffect(() => {
    if (!loaded && !loading && !error) {
      dispatch(
        loadAllBannersAction({
          errorCallBack: () => {
            void message.error('Ошибка при загрузке списка баннеров');
          },
        })
      );
    }
  }, [loaded, loading, error]);

  useEffect(() => {
    if (
      !actionsData.isLoaded &&
      !actionsData.isError &&
      !actionsData.isPending
    ) {
      dispatch(loadAllActionsGeneralAction());
    }
  }, [actionsData.isLoaded, actionsData.isError, actionsData.isPending]);

  const [selectedBanner, setSelectedBanner] = useState<IBanner | undefined>(
    undefined
  );

  const [drawerCreateOpen, setDrawerCreateOpen] = useState(false);
  const [drawerEditOpen, setDrawerEditOpen] = useState(false);

  const handleCloseDrawerCreate = useCallback(() => {
    setDrawerCreateOpen(false);
  }, []);

  const handleCloseDrawerEdit = useCallback(() => {
    setDrawerEditOpen(false);
  }, []);

  const handleEditBanner = useCallback((banner: IBanner) => {
    setSelectedBanner(() => banner);
    setDrawerEditOpen(true);
  }, []);

  const handleCreateBanner = useCallback(() => {
    setDrawerCreateOpen(true);
  }, []);

  const handleDeleteBanner = useCallback((banner: IBanner) => {
    dispatch(
      deleteBannerAction({
        bannerId: banner.id,
        mediaId: banner.media.id,
        successCallBack: () => {
          void message.success('Баннер удален');
        },
        errorCallBack: () => {
          void message.error('Ошибка при удалении баннера');
        },
      })
    );
  }, []);

  const [actionModalMeta, setActionModalMeta] = useState<{
    actionId?: string | number;
    order: number;
    genId?: string | number;
  }>({
    actionId: undefined,
    order: 0,
    genId: undefined,
  });
  const [actionCreateModalOpen, setActionCreateModalOpen] = useState(false);

  const handleOpenActionCreateModal = useCallback(() => {
    setActionCreateModalOpen(true);
  }, []);

  const handleCloseActionCreateModal = useCallback(() => {
    setActionCreateModalOpen(false);
  }, []);

  const handleSelectGeneralAction = useCallback(
    (order: number, actionId?: string | number, genId?: string | number) => {
      setActionModalMeta(() => ({
        actionId,
        order,
        genId,
      }));
      handleOpenActionCreateModal();
    },
    []
  );

  const actionsArray: {
    order: number;
    action?: IActionItem;
    genId?: string | number;
  }[] = useMemo(() => {
    let indexes: {
      order: number;
      action?: IActionItem;
      genId?: string | number;
    }[] = [
      {
        order: 0,
        action: undefined,
        genId: undefined,
      },
      {
        order: 1,
        action: undefined,
        genId: undefined,
      },
      {
        order: 2,
        action: undefined,
        genId: undefined,
      },
      {
        order: 3,
        action: undefined,
        genId: undefined,
      },
      {
        order: 4,
        action: undefined,
        genId: undefined,
      },
      {
        order: 5,
        action: undefined,
        genId: undefined,
      },
    ];

    actionsData.data.forEach((act) => {
      indexes = indexes.map((el) =>
        el.order === act.sort
          ? {
              order: el.order,
              action: act.actions[0],
              genId: act.id,
            }
          : el
      );
    });

    return indexes;
  }, [actionsData]);

  return (
    <Wrapper>
      <Space
        style={{
          justifyContent: 'space-between',
        }}
      >
        <Typography.Title level={2}>Главная / Баннеры</Typography.Title>
        <Button type={'primary'} onClick={handleCreateBanner} loading={loading}>
          Создать
        </Button>
      </Space>
      <BannersWrapper>
        <Row wrap={true} gutter={[8, 8]}>
          {data.map((banner) => (
            <Col span={8} key={banner.id}>
              <Card
                hoverable
                cover={
                  <Image
                    alt={getMediaFilePath(banner.media.fileName)}
                    src={getMediaFilePath(banner.media.fileName)}
                    style={{
                      maxHeight: 66,
                      objectFit: 'cover',
                    }}
                  />
                }
              >
                <Meta
                  title={
                    <TitleWrapper>
                      <a
                        target={'_blank'}
                        referrerPolicy={'no-referrer'}
                        href={banner.url}
                      >
                        {banner.url}
                      </a>
                      <Space size={8}>
                        <Button shape={'circle'}>
                          <EditOutlined
                            onClick={() => {
                              handleEditBanner(banner);
                            }}
                          />
                        </Button>
                        <Button
                          danger
                          type="primary"
                          shape={'circle'}
                          onClick={() => {
                            handleDeleteBanner(banner);
                          }}
                        >
                          <DeleteOutlined />
                        </Button>
                      </Space>
                    </TitleWrapper>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      </BannersWrapper>

      <Space
        style={{
          justifyContent: 'space-between',
        }}
      >
        <Typography.Title level={2}>Главная / Акции</Typography.Title>
        <Button type={'primary'} loading={actionsData.isPending}>
          Создать
        </Button>
      </Space>
      <BannersWrapper>
        <Row wrap={true} gutter={[8, 8]}>
          {actionsArray.map((act) => (
            <ActionItemCol
              action={act.action}
              order={act.order}
              handleChangeField={handleSelectGeneralAction}
              key={act.order}
              genId={act.genId}
            />
          ))}
        </Row>
      </BannersWrapper>

      <BannerDrawer
        onClose={handleCloseDrawerCreate}
        mode={'create'}
        isOpen={drawerCreateOpen}
      />

      <BannerDrawer
        onClose={handleCloseDrawerEdit}
        mode={'edit'}
        currentBanner={selectedBanner}
        isOpen={drawerEditOpen}
      />

      <GeneralActionModal
        selectedMeta={actionModalMeta}
        open={actionCreateModalOpen}
        onClose={handleCloseActionCreateModal}
      />
    </Wrapper>
  );
};
