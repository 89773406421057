import { ICustomer } from '../../../types/profile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from '../../../types/roles';

export type ICustomerResponse = {
  customers: ICustomer[];
};

type InitialType = {
  isLoaded: boolean;
  isPending: boolean;
  isError: boolean;
  customers: ICustomer[] | null;
};

const initialState: InitialType = {
  isLoaded: false,
  isPending: false,
  isError: false,
  customers: null,
};

const customersSlice = createSlice({
  name: 'customers/all',
  initialState: initialState as InitialType,
  reducers: {
    startCustomersLoadWorker(state) {
      state.isPending = true;
    },
    setData(state, action: PayloadAction<ICustomerResponse>) {
      state.customers = action.payload.customers;
      state.isError = false;
      state.isLoaded = true;
    },
    endCustomersWorker(state) {
      state.isPending = false;
    },
    setError(state) {
      state.isError = true;
      state.isLoaded = false;
    },
    editCustomer(state, action: PayloadAction<ICustomer>) {
      if (action.payload.role !== Role.CUSTOMER) {
        state.customers = (state.customers ?? []).filter(
          (el) => el.id !== action.payload.id
        );
      } else {
        state.customers = (state.customers ?? []).map((el) => {
          if (el.id === action.payload.id) {
            return action.payload;
          } else return el;
        });
      }
    },
    setNeedCustomersLoad(state) {
      state.isLoaded = false;
    },
  },
});

export const {
  startCustomersLoadWorker,
  endCustomersWorker,
  editCustomer,
  setData,
  setError,
  setNeedCustomersLoad,
} = customersSlice.actions;
export const customersListReducer = customersSlice.reducer;
