import { createSalonAction } from '../../state/salons/currentSalon/actions';
import { call, put } from 'redux-saga/effects';
import {
  createSalon,
  endLoadWorker,
  startLoadWorker,
} from '../../state/salons/salonsList/slice';
import { SalonAPI } from '../../api/salon';

export function* createSalonWorker(
  action: ReturnType<typeof createSalonAction>
): Generator {
  try {
    yield put(startLoadWorker());

    const response: any = yield call(
      SalonAPI.createSalon,
      action.payload.newData
    );

    yield put(createSalon(response.data));

    if (action.payload.successCallBack) {
      yield call(action.payload.successCallBack);
    }
    yield put(endLoadWorker());
  } catch (e) {
    if (action.payload.errorCallBack) {
      yield call(action.payload.errorCallBack);
    }
    yield put(endLoadWorker());
  }
}
