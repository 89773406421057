import { Theme, ThemeProvider } from '@emotion/react';
import React, { FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { MainColorsLight } from '../styles';
import { useThemeSelector } from '../state/app/selectors';
import { ConfigProvider } from 'antd';

export const lightTheme: Theme = {
  COLORS: MainColorsLight,
};

export const useCurrentTheme: () => Theme = () => {
  const { currentTheme } = useThemeSelector();

  return useMemo(() => {
    return lightTheme;
  }, [currentTheme]);
};

export const ThemeService: FC<PropsWithChildren> = ({ children }) => {
  const theme = useCurrentTheme();

  useEffect(() => {
    const root = document.documentElement;

    Object.keys(theme.COLORS.WHITE).forEach((whiteKey) => {
      root.style.setProperty(
        `--white${whiteKey}`,
        theme.COLORS.WHITE[whiteKey as keyof typeof theme.COLORS.WHITE]
      );
    });

    Object.keys(theme.COLORS.TEXT).forEach((textColorKey) => {
      root.style.setProperty(
        `--text-${textColorKey}`,
        theme.COLORS.TEXT[textColorKey as keyof typeof theme.COLORS.TEXT]
      );
    });

    Object.keys(theme.COLORS.ACCENT).forEach((accentColorKey) => {
      root.style.setProperty(
        `--accent-${accentColorKey}`,
        theme.COLORS.ACCENT[accentColorKey as keyof typeof theme.COLORS.ACCENT]
      );
    });
  }, [theme]);

  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider
        locale={{
          locale: 'ru',
        }}
        theme={{
          token: {
            colorPrimary: theme.COLORS.ACCENT.PRIMARY,
            colorSuccess: theme.COLORS.ACCENT.SUCCESS,
            colorWarning: theme.COLORS.ACCENT.WARNING,
            colorError: theme.COLORS.ACCENT.DANGER,
            colorInfo: theme.COLORS.ACCENT.SECONDARY,
            colorTextBase: theme.COLORS.TEXT.COMMON_TEXT,
            colorBgBase: theme.COLORS.WHITE.C100,
            borderRadius: 4,
          },
        }}
      >
        {children}
      </ConfigProvider>
    </ThemeProvider>
  );
};
