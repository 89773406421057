import { editCustomerAction } from '../../state/customers/customersList/actions';
import { call, put } from 'redux-saga/effects';
import {
  editCustomer,
  endCustomersWorker,
  setError,
  startCustomersLoadWorker,
} from '../../state/customers/customersList/slice';
import { CustomersAPI } from '../../api/customers';
import { message } from 'antd';
import { Role } from '../../types/roles';
import { setNeedSELoad } from '../../state/self-employers/list/slice';
import { setNeedClientsLoad } from '../../state/clients/clientsList/slice';
import { setNeedMastersLoad } from '../../state/masters/mastersList/slice';

export function* editWorker(
  action: ReturnType<typeof editCustomerAction>
): Generator {
  try {
    yield put(startCustomersLoadWorker());
    yield call(
      CustomersAPI.editClient,
      action.payload.newValues,
      action.payload.customer.id
    );

    yield put(editCustomer(action.payload.customer));

    if (action.payload.newValues.role !== Role.CUSTOMER) {
      switch (action.payload.newValues.role) {
        case Role.SELF_EMPLOYER:
          yield put(setNeedSELoad());
          break;
        case Role.MASTER:
          yield put(setNeedMastersLoad());
          break;
        case Role.CLIENT:
          yield put(setNeedClientsLoad());
          break;
      }
    }

    yield put(endCustomersWorker());
    yield call(message.success, 'Кастомер успешно изменен');
  } catch (e) {
    yield put(setError());
    yield put(endCustomersWorker());
    yield call(message.error, 'Кастомер изменения клиента');
  }
}
