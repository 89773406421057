import { createAction } from '@reduxjs/toolkit';

export const loginAction = createAction<{
  publicKey: string;
  secretKey: string;
  loginSuccessCallBack?: () => void;
  loginErrorCallBack?: () => void;
}>('user/auth/login');

export const refreshAction = createAction('user/auth/refresh');
