import { createBannerAction } from '../../../state/home-settings/banners/actions';
import { call, delay, put } from 'redux-saga/effects';
import {
  endBannersWorker,
  startBannersWorker,
  createBanner,
} from '../../../state/home-settings/banners/slice';
import { HomeSettingsAPI } from '../../../api/home-settings';

export function* createBannerWorker(
  action: ReturnType<typeof createBannerAction>
): Generator {
  try {
    yield put(startBannersWorker());

    yield delay(1000);

    const response: any = yield call(HomeSettingsAPI.createBanner, {
      mediaId: action.payload.mediaId,
      url: action.payload.url,
    });

    yield put(createBanner(response.data));

    yield put(endBannersWorker());
    if (action.payload.successCallBack) {
      yield call(action.payload.successCallBack);
    }
  } catch (e) {
    yield put(endBannersWorker());
    if (action.payload.errorCallBack) {
      yield call(action.payload.errorCallBack);
    }
  }
}
