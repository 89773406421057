import React, { FC, useCallback } from 'react';
import { Button, Card, Col, Image, message, Space, Tooltip } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { IActionItem } from '../../state/home-settings/actionsList/slice';
import styled from '@emotion/styled';
import { AddCard } from '../salons/salon-actions-list';
import { mainDomain } from '../../api';
import { HomeSettingsAPI } from '../../api/home-settings';
import { loadAllActionsGeneralAction } from '../../state/home-settings/actionsGeneral/actions';
import { useAppDispatch } from '../../state';

const { Meta } = Card;

const TitleWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const Title = styled('div')`
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface Props {
  order: number;
  action?: IActionItem;
  genId?: string | number;
  handleChangeField: (
    order: number,
    actionId?: string | number,
    genId?: string | number
  ) => void;
}

export const ActionItemCol: FC<Props> = ({
  order,
  action,
  handleChangeField,
  genId,
}) => {
  const dispatch = useAppDispatch();
  const handleSelectItem = useCallback(() => {
    handleChangeField(order, action?.id, genId);
  }, [action, order, genId]);

  const handleDeleteAction = useCallback(async () => {
    if (genId) {
      const loading = message.loading('Удаление', 0);

      try {
        await HomeSettingsAPI.deleteGeneral(genId);
        loading();
        dispatch(loadAllActionsGeneralAction());
        message.success('Акция удалена с главной');
      } catch (e) {
        loading();
        message.error('Ошибка при удалении с главной');
      }
    }
  }, [genId]);

  return (
    <Col span={6}>
      {action ? (
        <Card
          hoverable
          cover={
            <Image
              alt={action.title}
              src={action.thumbs}
              height={260}
              style={{
                objectFit: 'cover',
              }}
              preview={{
                src: action.fullImage,
              }}
            />
          }
        >
          <Meta
            title={
              <TitleWrapper>
                <Title>
                  №{order + 1} {action.title}
                </Title>
                <Space size={8}>
                  <Tooltip title="Перейти в салон">
                    <a
                      href={`${mainDomain}salon/${action.salon.id}`}
                      target={'_blank'}
                    >
                      <Button onClick={handleDeleteAction} shape={'circle'}>
                        <ExportOutlined />
                      </Button>
                    </a>
                  </Tooltip>
                  <Tooltip title="Редактировать слот">
                    <Button onClick={handleSelectItem} shape={'circle'}>
                      <EditOutlined />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Очистить слот">
                    <Button
                      onClick={handleDeleteAction}
                      danger
                      type="primary"
                      shape={'circle'}
                    >
                      <DeleteOutlined />
                    </Button>
                  </Tooltip>
                </Space>
              </TitleWrapper>
            }
          />
        </Card>
      ) : (
        <AddCard height={333} onClick={handleSelectItem}>
          <PlusOutlined />
          Создать №{order + 1}
        </AddCard>
      )}
    </Col>
  );
};
