import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Paths } from '../utils/routes/paths';
import { useAuthSelector } from '../state/auth/selectors';

export const LoginService: FC<PropsWithChildren> = ({ children }) => {
  const { authed, data } = useAuthSelector();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!authed || data === null || !data.accessToken) {
      if (location.pathname !== Paths.LOGIN) {
        navigate(Paths.LOGIN);
      }
    }
  }, [location, authed, data]);

  return <>{children}</>;
};
