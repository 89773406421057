import { takeLeading } from 'redux-saga/effects';
import {
  editSEAction,
  loadAllSEAction,
} from '../../state/self-employers/list/actions';
import { loadAllWorker } from './loadAllWorker';
import { editWorker } from './editWorker';

export function* seSaga(): Generator {
  yield takeLeading(loadAllSEAction, loadAllWorker);
  yield takeLeading(editSEAction, editWorker);
}
