import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useAuthSelector } from '../state/auth/selectors';
import { useProfileSelector } from '../state/profile/selectors';
import { useAppDispatch } from '../state';
import { authMeAction } from '../state/profile/actions';

export const CommonRequestsService: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();

  const { authed, data } = useAuthSelector();
  const { isLoaded, isPending, isError } = useProfileSelector();

  useEffect(() => {
    if (authed && data?.accessToken) {
      if (!isLoaded && !isPending && !isError) {
        dispatch(authMeAction());
      }
    }
  }, [authed, data, isLoaded, isPending, isError]);

  return <>{children}</>;
};
