import React, { FC, useEffect } from 'react';
import {
  Button,
  DatePicker,
  Drawer,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  Space,
} from 'antd';
import { IBaseProfile, IPartStatus, IProfileStatus } from '../../types/profile';
import locale from 'antd/es/date-picker/locale/ru_RU';
import dayjs from 'dayjs';
import { Role } from '../../types/roles';

export interface IBaseProfileForm {
  firstName: IBaseProfile['firstName'];
  lastName: IBaseProfile['lastName'];
  patronymic: IBaseProfile['patronymic'];
  birthDate: IBaseProfile['birthDate'];
  phone: IBaseProfile['phone'];
  email: IBaseProfile['email'];
  city: IBaseProfile['city'];
  role: IBaseProfile['role'];
  rating: IBaseProfile['rating'];
  king: IBaseProfile['king'];
  emailStatus: IBaseProfile['emailStatus'];
  phoneStatus: IBaseProfile['phoneStatus'];
  status: IBaseProfile['status'];
}

interface Props {
  open: boolean;
  onClose: () => void;
  selectedClient?: IBaseProfile;
  handleFinishForm: (values: IBaseProfileForm) => void;
}

interface FormProps {
  selectedClient: IBaseProfile;
  form: FormInstance<IBaseProfileForm>;
  handleFinishForm: (values: IBaseProfileForm) => void;
}

const EditProfileForm: FC<FormProps> = ({ form, handleFinishForm }) => {
  return (
    <Form form={form} onFinish={handleFinishForm} layout={'vertical'}>
      <Form.Item
        label={'Фамилия'}
        name={'lastName'}
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
        ]}
      >
        <Input placeholder={'Введите'} />
      </Form.Item>
      <Form.Item
        label={'Имя'}
        name={'firstName'}
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
        ]}
      >
        <Input placeholder={'Введите'} />
      </Form.Item>
      <Form.Item label={'Отчество'} name={'patronymic'}>
        <Input placeholder={'Введите'} />
      </Form.Item>
      <Form.Item label={'Пол'} name={'king'}>
        <Select
          options={[
            {
              value: 'FEMALE',
              label: 'Женский',
            },
            {
              value: 'MALE',
              label: 'Мужской',
            },
          ]}
          placeholder={'Введите'}
        />
      </Form.Item>
      <Form.Item
        label={'Дата рождения'}
        name={'birthDate'}
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
          () => ({
            validator(_, value) {
              if (
                !value ||
                dayjs(value?.toISOString()).isBefore(
                  dayjs().startOf('day').toISOString()
                )
              ) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Дата должна быть в прошлом'));
            },
          }),
        ]}
      >
        <DatePicker
          format={'DD.MM.YYYY'}
          locale={locale}
          showToday={false}
          style={{
            width: 260,
          }}
        />
      </Form.Item>
      <Form.Item
        label={'Номер телефона'}
        name={'phone'}
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
        ]}
      >
        <Input placeholder={'Введите'} />
      </Form.Item>
      <Form.Item label={'Email'} name={'email'}>
        <Input placeholder={'Введите'} />
      </Form.Item>
      <Form.Item label={'Город'} name={'city'}>
        <Input placeholder={'Введите'} />
      </Form.Item>
      <Form.Item label={'Роль'} name={'role'}>
        <Select
          options={[
            {
              value: Role.CLIENT,
              label: 'Клиент',
            },
            {
              value: Role.CUSTOMER,
              label: 'Кастомер',
            },
            {
              value: Role.MASTER,
              label: 'Мастер',
            },
            {
              value: Role.SELF_EMPLOYER,
              label: 'Самозанятый',
            },
          ]}
          placeholder={'Введите'}
        />
      </Form.Item>
      <Form.Item label={'Рейтинг'} name={'rating'}>
        <InputNumber placeholder={'От 0 до 5'} min={0} max={5} />
      </Form.Item>
      <Form.Item label={'Статус Email'} name={'emailStatus'}>
        <Select
          options={[
            {
              value: IPartStatus.APPROVED,
              label: 'Подтвержден',
            },
            {
              value: IPartStatus.UNAPPROVED,
              label: 'Не подтвержден',
            },
          ]}
          placeholder={'Выберите'}
        />
      </Form.Item>
      <Form.Item label={'Статус номера телефона'} name={'phoneStatus'}>
        <Select
          options={[
            {
              value: IPartStatus.APPROVED,
              label: 'Подтвержден',
            },
            {
              value: IPartStatus.UNAPPROVED,
              label: 'Не подтвержден',
            },
          ]}
          placeholder={'Выберите'}
        />
      </Form.Item>
      <Form.Item label={'Статус аккаунта'} name={'status'}>
        <Select
          options={[
            {
              value: IProfileStatus.ACTIVE,
              label: 'Активные',
            },
            {
              value: IProfileStatus.INACTIVE,
              label: 'Отключенный',
            },
          ]}
          placeholder={'Выберите'}
        />
      </Form.Item>
    </Form>
  );
};

export const EditProfileDrawer: FC<Props> = ({
  open,
  onClose,
  selectedClient,
  handleFinishForm,
}) => {
  const [form] = Form.useForm<IBaseProfileForm>();

  useEffect(() => {
    form.setFields([
      {
        name: 'firstName',
        value: selectedClient?.firstName,
      },
      {
        name: 'lastName',
        value: selectedClient?.lastName,
      },
      {
        name: 'patronymic',
        value: selectedClient?.patronymic,
      },
      {
        name: 'birthDate',
        value: selectedClient?.birthDate
          ? dayjs(selectedClient?.birthDate)
          : undefined,
      },
      {
        name: 'phone',
        value: selectedClient?.phone,
      },
      {
        name: 'email',
        value: selectedClient?.email,
      },
      {
        name: 'city',
        value: selectedClient?.city,
      },
      {
        name: 'role',
        value: selectedClient?.role,
      },
      {
        name: 'rating',
        value: selectedClient?.rating,
      },
      {
        name: 'king',
        value: selectedClient?.king,
      },
      {
        name: 'emailStatus',
        value: selectedClient?.emailStatus,
      },
      {
        name: 'phoneStatus',
        value: selectedClient?.phoneStatus,
      },
      {
        name: 'status',
        value: selectedClient?.status,
      },
    ]);
  }, [selectedClient]);

  return (
    <Drawer
      title="Изменить клиента"
      width={500}
      onClose={onClose}
      closable={false}
      maskClosable={false}
      open={open}
      extra={
        <Space>
          <Button onClick={onClose}>Отмена</Button>
          <Button type="primary" onClick={form.submit}>
            Применить
          </Button>
        </Space>
      }
    >
      {open && selectedClient && (
        <EditProfileForm
          form={form}
          selectedClient={selectedClient}
          handleFinishForm={handleFinishForm}
        />
      )}
    </Drawer>
  );
};
