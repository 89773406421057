import { createAction } from '@reduxjs/toolkit';
import { IBanner } from './slice';

export const loadAllBannersAction = createAction<{
  errorCallBack?: () => void;
  successCallBack?: () => void;
}>('banners/all/load');
export const createBannerAction = createAction<{
  url: string;
  mediaId: string | number;
  errorCallBack?: () => void;
  successCallBack?: () => void;
}>('banners/create');
export const deleteBannerAction = createAction<{
  errorCallBack?: () => void;
  successCallBack?: () => void;
  bannerId: string | number;
  mediaId: string | number;
}>('banner/delete');
export const editBannerAction = createAction<{
  errorCallBack?: () => void;
  successCallBack?: () => void;
  originalBannerObj: IBanner;
  newData: {
    url: string;
    mediaId: string | number;
  };
}>('banner/edit');
