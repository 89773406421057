import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  TreeSelect,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import moment, { Moment } from 'moment';
import { IEmployer, IMaster, IService } from '../../types/salon';
import { SalonAPI } from '../../api/salon';
import React from 'react';
import { useMastersListSelector } from '../../state/masters/mastersList/selectors';
import { useAppDispatch } from '../../state';
import { loadAllMastersAction } from '../../state/masters/mastersList/actions';
import locale from 'antd/es/date-picker/locale/ru_RU';
import { loadSalonByIdAction } from '../../state/salons/currentSalon/actions';

const { TextArea } = Input;
const { SHOW_PARENT } = TreeSelect;

interface Props {
  onCancel: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
  mode: 'edit' | 'create';
  services: IService[] | undefined;
  salonId: string;
  employer?: IEmployer;
}

interface IForm {
  descriptions?: string;
  employedDate: Moment;
  priceAspectRatio: number;
  masterId: string;
  serviceIds: string[];
}

type IFormProps = {
  mastersPending: boolean;
  mastersList: IMaster[] | null;
  instance: FormInstance<IForm>;
  servicesGroup: ITreeData[];
  salonId: string;
  setFormLoading: Dispatch<SetStateAction<boolean>>;
  mode: 'edit' | 'create';
  employer?: IEmployer;
  handleCancel: () => void;
};

interface ITreeData {
  title: string;
  value: string | number;
  key: string;
  children: {
    title: string;
    value: string;
    key: string;
  }[];
}

const AddMasterForm: FC<IFormProps> = ({
  instance,
  mastersPending,
  mastersList,
  servicesGroup,
  salonId,
  setFormLoading,
  mode,
  handleCancel,
  employer,
}) => {
  const dispatch = useAppDispatch();

  const handleFinish = useCallback(
    async (values: IForm) => {
      try {
        setFormLoading(true);

        let servicesList: (string | number)[] = [];

        values.serviceIds.forEach((el) => {
          const parentItem = servicesGroup.find(
            (servEl) => servEl.value === el
          );
          if (parentItem) {
            servicesList.push(
              ...parentItem.children.map(
                (parEl) => (parEl.value as string).split('$%')[0]
              )
            );
          } else {
            servicesList.push(el.split('$%')[0]);
          }
        });

        servicesList = servicesList.map((el) => Number(el));

        if (mode === 'create') {
          await SalonAPI.createEmp({
            property: {
              descriptions: values.descriptions ?? '',
              employedDate: values.employedDate.toISOString(),
              priceAspectRatio: values.priceAspectRatio,
            },
            masterId: values.masterId,
            salonIds: [salonId],
            serviceIds: servicesList as any,
          });
        } else {
          await SalonAPI.editEmp(
            {
              property: {
                descriptions: values.descriptions ?? '',
                employedDate: values.employedDate.toISOString(),
                priceAspectRatio: values.priceAspectRatio,
              },
              serviceIds: servicesList as any,
            },
            employer?.id as string
          );
        }

        dispatch(
          loadSalonByIdAction({
            id: salonId,
            errorCallBack: () => {
              void message.error(
                'Ошибка при загрузке салона. Попробуйте перезагрузить страницу'
              );
            },
          })
        );

        handleCancel();

        setFormLoading(() => false);
      } catch (e) {
        setFormLoading(() => false);
        message.error(
          `Ошибка при ${
            mode === 'create' ? 'создании' : 'редактировании'
          } мастера`
        );
      }
    },
    [salonId, mode, employer, servicesGroup]
  );

  return (
    <Form<IForm> form={instance} layout={'vertical'} onFinish={handleFinish}>
      {mode === 'create' && (
        <Form.Item
          name={'masterId'}
          label={'Мастер'}
          rules={[
            {
              required: true,
              message: 'Поле обязательно для заполнения',
            },
          ]}
        >
          <Select
            showSearch
            options={mastersList?.map((el) => ({
              value: el.id,
              label: `${el.phone} | ${el.firstName} ${el.lastName}`,
            }))}
            optionFilterProp={'label'}
            loading={mastersPending}
            placeholder={'Выберите'}
            allowClear
          />
        </Form.Item>
      )}
      <Form.Item name={'descriptions'} label={'Описание мастера'}>
        <TextArea placeholder={'Введите'} />
      </Form.Item>
      <Form.Item
        name={'employedDate'}
        label={'Дата выхода на работу'}
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
        ]}
      >
        <DatePicker locale={locale} format={'DD.MM.YYYY'} />
      </Form.Item>
      {servicesGroup && (
        <Form.Item
          name={'serviceIds'}
          label={'Услуги предоставляемые мастером'}
          rules={[
            {
              required: true,
              message: 'Поле обязательно для заполнения',
            },
          ]}
        >
          <TreeSelect
            showSearch
            placeholder={'Выберите'}
            showCheckedStrategy={SHOW_PARENT}
            treeData={servicesGroup}
            treeCheckable
            allowClear
          />
        </Form.Item>
      )}
      <Form.Item
        name={'priceAspectRatio'}
        label={'Множитель стоимость'}
        tooltip={
          'Стоимость услуги у данного мастера будет умножена на введенное значение'
        }
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
          {
            type: 'number',
            message: 'Введенное значение должно быть числовым',
          },
        ]}
      >
        <InputNumber
          placeholder={'Введите'}
          step={0.1}
          type={'number'}
          min={0}
          width={200}
        />
      </Form.Item>
    </Form>
  );
};

export const AddMastersModal: FC<Props> = ({
  mode,
  isOpen,
  onCancel,
  services,
  salonId,
  employer,
}) => {
  const dispatch = useAppDispatch();
  const { isPending, masters, isLoaded, isError } = useMastersListSelector();

  const [form] = useForm<IForm>();

  const [formLoading, setFormLoading] = useState(false);

  const handleOk = useCallback(() => {
    form.submit();
  }, []);

  const handleCancel = useCallback(() => {
    onCancel(() => false);
  }, []);

  const handleLoadMastersList = useCallback(async () => {
    dispatch(loadAllMastersAction());
  }, []);

  useEffect(() => {
    if (!isLoaded && !isPending && !isError) {
      void handleLoadMastersList();
    }
  }, []);

  useEffect(() => {
    if (mode === 'edit') {
      form.setFields([
        {
          name: 'serviceIds',
          value: employer?.services.map(
            (el) => `${el.id}$%${el.service.title}`
          ),
        },
        {
          name: 'descriptions',
          value: employer?.property?.descriptions,
        },
        {
          name: 'employedDate',
          value: employer?.property?.employedDate
            ? moment(employer?.property?.employedDate)
            : undefined,
        },
        {
          name: 'priceAspectRatio',
          value: employer?.property?.priceAspectRatio,
        },
      ]);
    }
  }, [employer, mode]);

  const servicesGroup: ITreeData[] = useMemo(() => {
    return Object.values(
      services?.reduce((res: Record<string, ITreeData>, acc) => {
        if (res[acc.service.parent.id]) {
          res = {
            ...res,
            [acc.service.parent.id]: {
              ...res[acc.service.parent.id],
              children: [
                ...res[acc.service.parent.id].children,
                {
                  value: acc.id + '$%' + acc.service.title,
                  title: acc.service.title,
                  key: acc.id + '$%' + acc.service.title,
                },
              ],
            },
          };
        } else {
          res = {
            ...res,
            [acc.service.parent.id]: {
              title: acc.service.parent.title,
              value: acc.service.parent.id + '$%' + acc.service.parent.title,
              key: acc.service.parent.id + '$%' + acc.service.parent.title,
              children: [
                {
                  value: acc.id + '$%' + acc.service.title,
                  title: acc.service.title,
                  key: acc.id + '$%' + acc.service.title,
                },
              ],
            },
          };
        }

        return res;
      }, {} as Record<string, ITreeData>) ?? []
    );
  }, [services]);

  return (
    <Modal
      title={mode === 'create' ? 'Добавить мастера' : 'Изменить мастера'}
      open={isOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={mode === 'create' ? 'Создать' : 'Изменить'}
      cancelText={'Отмена'}
      closable={false}
      maskClosable={false}
      destroyOnClose
      okButtonProps={{
        loading: formLoading,
      }}
    >
      {isOpen && (
        <AddMasterForm
          mastersPending={isPending}
          mastersList={masters as any}
          instance={form}
          servicesGroup={servicesGroup}
          salonId={salonId}
          setFormLoading={setFormLoading}
          mode={mode}
          employer={employer}
          handleCancel={handleCancel}
        />
      )}
    </Modal>
  );
};
