import { baseAPIInstance } from '../index';
import { AxiosResponse } from 'axios';

export enum IMediaType {
  LOGO = 'logo',
  AVATAR = 'avatar',
  BANNER = 'banner',
  PORTFOLIO = 'portfolio',
  SALON_PHOTO = 'salonPhoto',
}

export interface IFileResponseData {
  fileName: string;
  id: number;
  mimeType: string;
  size: number;
  url: string;
}

interface ICommonAPI {
  uploadFile: (
    type: IMediaType,
    file: File
  ) => Promise<AxiosResponse<IFileResponseData>>;
  deleteFile: (fileId: string | number) => Promise<AxiosResponse>;
}

export const CommonAPI: ICommonAPI = {
  uploadFile: async (type, file) => {
    const formData = new FormData();

    formData.set('mediaType', type);
    formData.set('file', file);

    return baseAPIInstance.post('/media/file-upload', formData);
  },
  deleteFile: async (id) => {
    return baseAPIInstance.delete(`/media/${id}`);
  },
};
