import { editSEAction } from '../../state/self-employers/list/actions';
import { call, put } from 'redux-saga/effects';
import {
  endSEWorker,
  setError,
  startSEWorker,
  editSE,
} from '../../state/self-employers/list/slice';
import { SelfEmployersAPI } from '../../api/self-employers';
import { Role } from '../../types/roles';
import { setNeedCustomersLoad } from '../../state/customers/customersList/slice';
import { setNeedClientsLoad } from '../../state/clients/clientsList/slice';
import { setNeedMastersLoad } from '../../state/masters/mastersList/slice';

export function* editWorker(
  action: ReturnType<typeof editSEAction>
): Generator {
  try {
    yield put(startSEWorker());

    yield call(
      SelfEmployersAPI.editSelfEmployer,
      action.payload.newValues,
      action.payload.selfEmployer.id
    );
    yield put(
      editSE({
        ...action.payload.selfEmployer,
        ...action.payload.newValues,
      })
    );

    if (action.payload.newValues.role !== Role.SELF_EMPLOYER) {
      switch (action.payload.newValues.role) {
        case Role.MASTER:
          yield put(setNeedMastersLoad());
          break;
        case Role.CUSTOMER:
          yield put(setNeedCustomersLoad());
          break;
        case Role.CLIENT:
          yield put(setNeedClientsLoad());
          break;
      }
    }

    yield put(endSEWorker());
  } catch (e) {
    yield put(setError());
    yield put(endSEWorker());
  }
}
