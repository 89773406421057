import { createAction } from '@reduxjs/toolkit';
import { IClient } from '../../../types/profile';
import { IBaseProfileForm } from '../../../components/base-profiles/edit-profile-drawer';

export const loadAllClientsAction = createAction('base-profiles/all/load');
export const editClientAction = createAction<{
  newValues: IBaseProfileForm;
  client: IClient;
  successCallback: () => void;
}>('base-profiles/edit');
