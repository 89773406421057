import { all, call, put, spawn } from 'redux-saga/effects';
import { message } from 'antd';
import { authSaga } from './auth/authSaga';
import { profileSaga } from './profile/profileSaga';
import { clientsSaga } from './clients/clientsSaga';
import { customersSaga } from './customers/customersSaga';
import { mastersSaga } from './masters/mastersSaga';
import { salonsSaga } from './salons/salonsSaga';
import { homeSettingsSaga } from './homeSettings/homeSettingsSaga';
import { seSaga } from './selfEmployers/seSaga';

function* rootSaga(): Generator {
  const sagas = [
    authSaga,
    profileSaga,
    clientsSaga,
    customersSaga,
    mastersSaga,
    salonsSaga,
    homeSettingsSaga,
    seSaga,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e: any) {
            if (e.response.status === 401) {
              yield put({ type: 'reset/token' });
            } else {
              void message.error(e.response.data.message);
            }
          }
        }
      })
    )
  );
}

export default rootSaga;
