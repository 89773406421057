import { baseAPIInstance } from '../index';
import { AxiosResponse } from 'axios';
import { ISalonsResponse } from '../../state/salons/salonsList/slice';
import { IListedSalon, ISalon } from '../../types/salon';
import { ISalonEdited } from '../../components/salons/edit-salon-drawer';

export interface ICreateEmployer {
  property: {
    descriptions: string;
    employedDate: string;
    priceAspectRatio: number;
  };
  masterId: string;
  salonIds: string[];
  serviceIds: string[];
}

export interface IPatchEmployer {
  property: {
    descriptions: string;
    employedDate: string;
    priceAspectRatio: number;
  };
  serviceIds: string[];
}

interface ISalonAPI {
  getAll: () => Promise<AxiosResponse<ISalonsResponse>>;
  getById: (id: string | number) => Promise<AxiosResponse<ISalon>>;
  editSalonMainInfo: (
    data: ISalonEdited,
    id: string | number
  ) => Promise<AxiosResponse>;
  createSalon: (data: ISalonEdited) => Promise<AxiosResponse<IListedSalon>>;
  deleteSalon: (id: string | number) => Promise<AxiosResponse>;
  createEmp: (data: ICreateEmployer) => Promise<AxiosResponse>;
  editEmp: (data: IPatchEmployer, id: string) => Promise<AxiosResponse>;
  deleteEmployer: (id: string) => Promise<AxiosResponse>;
}

export const SalonAPI: ISalonAPI = {
  getAll: async () => {
    return baseAPIInstance.get('/salon/all');
  },
  getById: async (id) => {
    return baseAPIInstance.get(`salon/${id}`);
  },
  editSalonMainInfo: async (data, id) => {
    return baseAPIInstance.patch(`salon/${id}`, data);
  },
  createSalon: async (data) => {
    return baseAPIInstance.post('salon', data);
  },
  deleteSalon: async (id) => {
    return baseAPIInstance.delete(`salon/${id}`);
  },
  createEmp: async (data) => {
    return baseAPIInstance.post('/employer', data);
  },
  editEmp: async (data, id) => {
    return baseAPIInstance.patch(`employer/${id}`, data);
  },
  deleteEmployer: async (id) => {
    return baseAPIInstance.delete(`employer/${id}`);
  },
};
