import { takeLeading } from 'redux-saga/effects';
import {
  editCustomerAction,
  loadAllCustomersAction,
} from '../../state/customers/customersList/actions';
import { loadAllWorker } from './loadAllWorker';
import { editWorker } from './editWorker';

export function* customersSaga(): Generator {
  yield takeLeading(loadAllCustomersAction, loadAllWorker);
  yield takeLeading(editCustomerAction, editWorker);
}
