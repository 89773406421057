import { deleteSalonAction } from '../../state/salons/currentSalon/actions';
import { call, put } from 'redux-saga/effects';
import {
  deleteSalon,
  endLoadWorker,
  startLoadWorker,
} from '../../state/salons/salonsList/slice';
import { SalonAPI } from '../../api/salon';

export function* deleteSalonWorker(
  action: ReturnType<typeof deleteSalonAction>
): Generator {
  try {
    yield put(startLoadWorker());

    yield call(SalonAPI.deleteSalon, action.payload.salonId);

    yield put(deleteSalon(action.payload.salonId));

    if (action.payload.successCallBack) {
      yield call(action.payload.successCallBack);
    }
    yield put(endLoadWorker());
  } catch (e) {
    if (action.payload.errorCallBack) {
      yield call(action.payload.errorCallBack);
    }
    yield put(endLoadWorker());
  }
}
