import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useClientsListSelector } from '../state/clients/clientsList/selectors';
import { useAppDispatch } from '../state';
import {
  editClientAction,
  loadAllClientsAction,
} from '../state/clients/clientsList/actions';
import styled from '@emotion/styled';
import { Table, Typography } from 'antd';
import {
  EditProfileDrawer,
  IBaseProfileForm,
} from '../components/base-profiles/edit-profile-drawer';
import { IBaseProfile } from '../types/profile';
import { baseProfileColumns, mapBaseProfileToTable } from '../utils/profiles';

export const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 40px;
`;

export const TableWrapper = styled('div')`
  margin-top: 12px;
`;

export const Clients: FC = () => {
  const dispatch = useAppDispatch();
  const { isPending, clients, isError, isLoaded } = useClientsListSelector();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState<
    undefined | IBaseProfile
  >();

  const onModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleSelectClient = useCallback((client: IBaseProfile) => {
    setSelectedClient(client);
    setModalOpen(true);
  }, []);

  useEffect(() => {
    if (!isLoaded && !isPending && !isError) {
      dispatch(loadAllClientsAction());
    }
  }, [isLoaded, isPending, isError]);

  const dataSource = useMemo(
    () =>
      clients && clients.length > 0
        ? clients.map((el, index) =>
            mapBaseProfileToTable(el, index, handleSelectClient)
          )
        : [],
    [clients]
  );

  const handleFinishEditForm = useCallback(
    (values: IBaseProfileForm) => {
      if (selectedClient) {
        dispatch(
          editClientAction({
            newValues: values,
            client: {
              ...selectedClient,
              ...values,
            },
            successCallback: () => {
              onModalClose();
            },
          })
        );
      }
    },
    [selectedClient]
  );

  return (
    <Wrapper>
      <Typography.Title level={2}>Клиенты в системе</Typography.Title>
      <TableWrapper>
        <Table
          dataSource={dataSource}
          columns={baseProfileColumns}
          pagination={false}
          loading={isPending}
          locale={{
            emptyText: 'Нет данных',
          }}
        />
      </TableWrapper>
      <EditProfileDrawer
        open={modalOpen}
        onClose={onModalClose}
        selectedClient={selectedClient}
        handleFinishForm={handleFinishEditForm}
      />
    </Wrapper>
  );
};
