import { AxiosResponse } from 'axios';
import { ICustomer } from '../../types/profile';
import { baseAPIInstance } from '../index';
import { ICustomerResponse } from '../../state/customers/customersList/slice';

interface ICustomersAPI {
  getAll: () => Promise<AxiosResponse<ICustomerResponse>>;
  editClient: (
    data: Partial<ICustomer>,
    id: string | number
  ) => Promise<AxiosResponse>;
}

export const CustomersAPI: ICustomersAPI = {
  getAll: async () => {
    return baseAPIInstance.get('/customer/all');
  },
  editClient: async (data, id) => {
    return baseAPIInstance.patch(`/account/${id}`, data);
  },
};
