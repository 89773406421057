import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import {
  endLoadWorker,
  setError,
  startLoadWorker,
  setList,
} from '../../state/salons/salonsList/slice';
import { SalonAPI } from '../../api/salon';

export function* loadAllWorker(): Generator {
  try {
    yield put(startLoadWorker());

    const response: any = yield call(SalonAPI.getAll);
    yield put(setList(response.data));

    yield put(endLoadWorker());
  } catch (e) {
    yield put(setError());
    yield put(endLoadWorker());
    yield call(message.error, 'Ошибка при загрузке списка салонов');
  }
}
