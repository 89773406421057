import { AxiosResponse } from 'axios';
import { IBanner } from '../../state/home-settings/banners/slice';
import { baseAPIInstance } from '../index';
import { IActionItem } from '../../state/home-settings/actionsList/slice';
import { IActionFinalCreate } from '../../components/home-settings/action-drawer';
import { IGeneratedAction } from '../../state/home-settings/actionsGeneral/slice';

interface IHomeSettingsAPI {
  getAllBanners: () => Promise<AxiosResponse<IBanner[]>>;
  createBanner: (data: {
    url: string;
    mediaId: string | number;
  }) => Promise<AxiosResponse<IBanner>>;
  deleteBanner: (bannerId: string | number) => Promise<AxiosResponse>;
  editBanner: (
    bannerId: string | number,
    data: {
      url: string;
      mediaId: string | number;
    }
  ) => Promise<AxiosResponse<IBanner>>;
  getAllActions: () => Promise<
    AxiosResponse<{
      actions: IActionItem[];
    }>
  >;
  createAction: (
    data: IActionFinalCreate
  ) => Promise<AxiosResponse<IActionItem>>;
  deleteAction: (id: string) => Promise<AxiosResponse>;
  editAction: (
    data: Partial<IActionFinalCreate>,
    id: string
  ) => Promise<AxiosResponse<IActionItem>>;
  getAllAGeneral: () => Promise<AxiosResponse<IGeneratedAction[]>>;
  createGeneral: (
    order: number,
    actionId: string | number
  ) => Promise<AxiosResponse<IGeneratedAction>>;
  editGeneral: (
    order: number,
    actionId: string | number,
    generalId: string | number
  ) => Promise<AxiosResponse<IGeneratedAction>>;
  deleteGeneral: (genId: string | number) => Promise<AxiosResponse>;
}

export const HomeSettingsAPI: IHomeSettingsAPI = {
  getAllBanners: async () => {
    return baseAPIInstance.get('/banner/all');
  },
  createBanner: async (data) => {
    return baseAPIInstance.post('/banner', data);
  },
  deleteBanner: async (id) => {
    return baseAPIInstance.delete(`/banner/${id}`);
  },
  editBanner: async (id, data) => {
    return baseAPIInstance.patch(`/banner/${id}`, data);
  },
  getAllActions: async () => {
    return baseAPIInstance.get('/action/all');
  },
  createAction: async (data) => {
    return baseAPIInstance.post('action', data);
  },
  deleteAction: async (id) => {
    return baseAPIInstance.delete(`action/${id}`);
  },
  editAction: async (data, id) => {
    return baseAPIInstance.put(`action/${id}`, data);
  },
  getAllAGeneral: async () => {
    return baseAPIInstance.get('/action/general');
  },
  createGeneral: async (ord, act) => {
    return baseAPIInstance.post('/action/general', {
      actionsIds: [act],
      sort: ord,
    });
  },
  editGeneral: async (ord, act, genId) => {
    return baseAPIInstance.put(`/action/general/${genId}`, {
      actionsIds: [act],
      sort: ord,
    });
  },
  deleteGeneral: async (genId) => {
    return baseAPIInstance.delete(`/action/general/${genId}`);
  },
};
