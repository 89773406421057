import React, { FC, useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { IEmployer, IService } from '../../types/salon';
import { Button, Empty, message } from 'antd';
import { SalonAPI } from '../../api/salon';
import { AddMastersModal } from './add-masters-modal';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

interface Props {
  salonId: string | number | undefined;
  employers: IEmployer[] | undefined;
  services: IService[] | undefined;
}

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`;

const ListWrapper = styled('div')`
  margin-top: 12px;
`;

const MastersItem = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 6px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.04);
`;

const MasterButtons = styled('div')`
  display: flex;
  gap: 8px;
`;

export const SalonMastersList: FC<Props> = ({
  salonId,
  employers,
  services,
}) => {
  const [addMasterModal, setAddMasterModal] = useState(false);
  const [editMasterModal, setEditMasterModal] = useState(false);

  const [selectedEmployer, setSelectedEmployer] = useState<
    IEmployer | undefined
  >(undefined);

  const handleEditEmp = useCallback((emp: IEmployer) => {
    setSelectedEmployer(() => emp);
    setEditMasterModal(() => true);
  }, []);

  const handleDeleteEmployer = useCallback(async (id: string) => {
    try {
      await SalonAPI.deleteEmployer(id);
      void message.success('Мастер удален');
    } catch (e) {
      void message.error('Ошибка при удалении мастера');
    }
  }, []);

  return (
    <div>
      <ButtonWrapper>
        <span>Список</span>
        <Button
          type="primary"
          onClick={() => {
            setAddMasterModal(true);
          }}
        >
          Добавить мастера
        </Button>
      </ButtonWrapper>
      <ListWrapper>
        {employers ? (
          <>
            {employers.length > 0 ? (
              <>
                {employers.map((el) => (
                  <MastersItem key={el.id}>
                    <span>
                      {el.master.firstName} {el.master.lastName}
                    </span>
                    <MasterButtons>
                      <Button
                        shape={'circle'}
                        type={'primary'}
                        onClick={() => {
                          handleEditEmp(el);
                        }}
                      >
                        <EditOutlined />
                      </Button>
                      <Button
                        shape={'circle'}
                        danger
                        type={'primary'}
                        onClick={() => {
                          void handleDeleteEmployer(el.id);
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </MasterButtons>
                  </MastersItem>
                ))}
              </>
            ) : (
              <Empty description="Мастера отсутствуют" />
            )}
          </>
        ) : (
          <Empty description="Нет данных" />
        )}
      </ListWrapper>

      <AddMastersModal
        isOpen={addMasterModal}
        onCancel={setAddMasterModal}
        mode={'create'}
        services={services}
        salonId={salonId as string}
      />
      <AddMastersModal
        isOpen={editMasterModal}
        onCancel={setEditMasterModal}
        mode={'edit'}
        services={services}
        salonId={salonId as string}
        employer={selectedEmployer}
      />
    </div>
  );
};
