import { takeLeading } from 'redux-saga/effects';
import { loadAllSalonsAction } from '../../state/salons/salonsList/actions';
import { loadAllWorker } from './loadAllWorker';
import {
  createSalonAction,
  deleteSalonAction,
  editSalonMainDataByIdAction,
  loadSalonByIdAction,
} from '../../state/salons/currentSalon/actions';
import { loadCurrentWorker } from './loadCurrentWorker';
import { editSalonMainDataWorker } from './editSalonMainDataWorker';
import { createSalonWorker } from './createSalonWorker';
import { deleteSalonWorker } from './deleteSalonWorker';

export function* salonsSaga(): Generator {
  yield takeLeading(loadAllSalonsAction, loadAllWorker);
  yield takeLeading(loadSalonByIdAction, loadCurrentWorker);
  yield takeLeading(editSalonMainDataByIdAction, editSalonMainDataWorker);
  yield takeLeading(createSalonAction, createSalonWorker);
  yield takeLeading(deleteSalonAction, deleteSalonWorker);
}
