import { takeLeading } from 'redux-saga/effects';
import {
  editClientAction,
  loadAllClientsAction,
} from '../../state/clients/clientsList/actions';
import { loadAllWorker } from './loadAllWorker';
import { editWorker } from './editWorker';

export function* clientsSaga(): Generator {
  yield takeLeading(loadAllClientsAction, loadAllWorker);
  yield takeLeading(editClientAction, editWorker);
}
