import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Typography } from 'antd';
import { TableWrapper, Wrapper } from './clients';
import { useMastersListSelector } from '../state/masters/mastersList/selectors';
import { baseProfileColumns, mapBaseProfileToTable } from '../utils/profiles';
import { IBaseProfile } from '../types/profile';
import { useAppDispatch } from '../state';
import {
  editMasterAction,
  loadAllMastersAction,
} from '../state/masters/mastersList/actions';
import {
  EditProfileDrawer,
  IBaseProfileForm,
} from '../components/base-profiles/edit-profile-drawer';

export const Masters: FC = () => {
  const dispatch = useAppDispatch();
  const { isPending, masters, isLoaded, isError } = useMastersListSelector();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMaster, setSelectedMaster] = useState<IBaseProfile>();

  const handleSelectMaster = useCallback((master: IBaseProfile) => {
    setSelectedMaster(master);
    setModalOpen(true);
  }, []);

  const onModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  useEffect(() => {
    if (!isLoaded && !isPending && !isError) {
      dispatch(loadAllMastersAction());
    }
  }, [isLoaded, isPending, isError]);

  const handleFinishForm = useCallback(
    (values: IBaseProfileForm) => {
      if (selectedMaster) {
        dispatch(
          editMasterAction({
            newValues: values,
            master: {
              ...selectedMaster,
              ...values,
            },
            successCallBack: () => {
              onModalClose();
            },
          })
        );
      }
    },
    [selectedMaster]
  );

  const dataSource = useMemo(
    () =>
      masters && masters.length > 0
        ? masters.map((el, index) =>
            mapBaseProfileToTable(el, index, handleSelectMaster)
          )
        : [],
    [masters]
  );

  return (
    <Wrapper>
      <Typography.Title level={2}>Мастера в системе</Typography.Title>
      <TableWrapper>
        <Table
          dataSource={dataSource}
          columns={baseProfileColumns}
          loading={isPending}
          pagination={false}
          locale={{
            emptyText: 'Нет данных',
          }}
        />
      </TableWrapper>
      <EditProfileDrawer
        open={modalOpen}
        onClose={onModalClose}
        selectedClient={selectedMaster}
        handleFinishForm={handleFinishForm}
      />
    </Wrapper>
  );
};
