import { createAction } from '@reduxjs/toolkit';
import { ICustomer } from '../../../types/profile';
import { IBaseProfileForm } from '../../../components/base-profiles/edit-profile-drawer';

export const loadAllCustomersAction = createAction('customers/all/load');
export const editCustomerAction = createAction<{
  newValues: IBaseProfileForm;
  customer: ICustomer;
  successCallback: () => void;
}>('customers/edit');
