import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { TableWrapper, Wrapper } from './clients';
import { Table, Typography } from 'antd';
import { useAppDispatch } from '../state';
import { useSESelector } from '../state/self-employers/list/selectors';
import {
  editSEAction,
  loadAllSEAction,
} from '../state/self-employers/list/actions';
import { baseProfileColumns, mapBaseProfileToTable } from '../utils/profiles';
import { IBaseProfile } from '../types/profile';
import {
  EditProfileDrawer,
  IBaseProfileForm,
} from '../components/base-profiles/edit-profile-drawer';

export const SelfEmployers: FC = () => {
  const dispatch = useAppDispatch();
  const seData = useSESelector();

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<
    IBaseProfile | undefined
  >();

  const handleSelectCustomer = useCallback((customer: IBaseProfile) => {
    setSelectedCustomer(customer);
    setModalOpen(true);
  }, []);

  const onModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  useEffect(() => {
    if (!seData.isLoaded && !seData.isError && !seData.isPending) {
      dispatch(loadAllSEAction());
    }
  }, [seData]);

  const handleFinishEditForm = useCallback(
    (values: IBaseProfileForm) => {
      if (selectedCustomer) {
        dispatch(
          editSEAction({
            newValues: values,
            selfEmployer: {
              ...selectedCustomer,
              ...values,
            },
            successCallBack: () => {
              onModalClose();
            },
          })
        );
      }
    },
    [selectedCustomer]
  );

  const dataSource = useMemo(
    () =>
      seData.selfEmployers && seData.selfEmployers.length > 0
        ? seData.selfEmployers.map((el, index) =>
            mapBaseProfileToTable(el, index, handleSelectCustomer)
          )
        : [],
    [seData]
  );

  return (
    <Wrapper>
      <Typography.Title level={2}>Самозанятые в системе</Typography.Title>
      <TableWrapper>
        <Table
          pagination={false}
          locale={{
            emptyText: 'Нет данных',
          }}
          columns={baseProfileColumns}
          dataSource={dataSource}
          loading={seData.isPending}
        />
      </TableWrapper>
      <EditProfileDrawer
        handleFinishForm={handleFinishEditForm}
        onClose={onModalClose}
        selectedClient={selectedCustomer}
        open={modalOpen}
      />
    </Wrapper>
  );
};
