import React, { FC } from 'react';
import {
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  TimePicker,
} from 'antd';
import { ISalonForm } from './edit-salon-drawer';
import { ICustomer } from '../../types/profile';

const { TextArea } = Input;

type TProps = {
  form: FormInstance<ISalonForm>;
  onFinish: (values: ISalonForm) => void;
  mode?: 'edit' | 'client';
  customers?: ICustomer[];
};

export const SalonDataForm: FC<TProps> = ({
  form,
  onFinish,
  mode,
  customers = [] as ICustomer[],
}) => {
  return (
    <Form form={form} layout={'vertical'} onFinish={onFinish}>
      <Form.Item
        name={'title'}
        label={'Название'}
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
        ]}
      >
        <Input placeholder={'Введите'} />
      </Form.Item>
      {mode === 'edit' && (
        <Form.Item name={'ownerId'} label={'Владелец'}>
          <Select
            options={customers.map((el) => ({
              label: `${el.firstName} ${el.lastName}`,
              value: el.id,
            }))}
            placeholder={'Введите'}
            optionLabelProp={'label'}
          />
        </Form.Item>
      )}
      <Form.Item name={'description'} label={'Описание'}>
        <TextArea
          autoSize={{ minRows: 2, maxRows: 6 }}
          placeholder={'Введите'}
        />
      </Form.Item>
      <Form.Item name={'email'} label={'Email'}>
        <Input placeholder={'Введите'} />
      </Form.Item>
      <Form.Item
        name={'phone'}
        label={'Номера телефона'}
        tooltip={'Если номеров несколько, введите через символ "|"'}
      >
        <Input placeholder={'Введите'} />
      </Form.Item>
      <Form.Item name={'city'} label={'Город'}>
        <Input placeholder={'Введите'} />
      </Form.Item>
      <Form.Item name={'address'} label={'Адрес'}>
        <Input placeholder={'Введите'} />
      </Form.Item>
      <Form.Item name={'metro'} label={'Метро'}>
        <Input placeholder={'Введите'} />
      </Form.Item>
      <Form.Item
        name={'latitude'}
        label={'Широта'}
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
        ]}
      >
        <InputNumber
          style={{
            width: 200,
          }}
          type={'number'}
          placeholder={'Введите'}
        />
      </Form.Item>
      <Form.Item
        name={'longitude'}
        label={'Долгота'}
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
        ]}
      >
        <InputNumber
          style={{
            width: 200,
          }}
          type={'number'}
          placeholder={'Введите'}
        />
      </Form.Item>
      <Form.Item
        name={'rating'}
        label={'Рейтинг'}
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
        ]}
      >
        <InputNumber min={0} max={5} type={'number'} placeholder={'Введите'} />
      </Form.Item>
      <Form.Item
        name={'startJobTime'}
        label={'Время начала работы'}
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
        ]}
      >
        <TimePicker minuteStep={15} showSecond={false} format={'HH:mm'} />
      </Form.Item>
      <Form.Item
        name={'endJobTime'}
        label={'Время окончания работы'}
        rules={[
          {
            required: true,
            message: 'Поле обязательно для заполнения',
          },
        ]}
      >
        <TimePicker minuteStep={15} showSecond={false} format={'HH:mm'} />
      </Form.Item>
      <Form.Item name={'website'} label={'Сайт'}>
        <Input placeholder={'Введите'} />
      </Form.Item>
      <Form.Item
        name={'social'}
        label={'Соц. сети'}
        tooltip={'Если несколько, введите через символ "|"'}
      >
        <Input placeholder={'Введите'} />
      </Form.Item>
    </Form>
  );
};
