import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMedia } from '../../../types/common';

export interface IBanner {
  id: number | string;
  url: string;
  media: IMedia;
}

interface IState {
  loaded: boolean;
  loading: boolean;
  error: boolean;
  data: IBanner[];
}

const initialState: IState = {
  loaded: false,
  loading: false,
  data: [],
  error: false,
};

const homeBannersSlice = createSlice({
  name: 'home/banners/all',
  initialState: initialState as IState,
  reducers: {
    startBannersWorker(state) {
      state.loading = true;
    },
    endBannersWorker(state) {
      state.loading = false;
    },
    setBannersData(state, action: PayloadAction<IBanner[]>) {
      state.data = action.payload;
      state.loaded = true;
      state.error = false;
    },
    setBannersError(state) {
      state.error = true;
      state.loaded = false;
    },
    editBanner(state, action: PayloadAction<IBanner>) {
      state.data = state.data.map((el) =>
        el.id === action.payload.id ? action.payload : el
      );
    },
    deleteBanner(state, action: PayloadAction<string | number>) {
      state.data = [...state.data.filter((el) => el.id !== action.payload)];
    },
    createBanner(state, action: PayloadAction<IBanner>) {
      state.data = [...state.data, action.payload];
    },
  },
});

export const {
  startBannersWorker,
  editBanner,
  endBannersWorker,
  setBannersData,
  setBannersError,
  deleteBanner,
  createBanner,
} = homeBannersSlice.actions;
export const homeBannersReducer = homeBannersSlice.reducer;
