import { AxiosResponse } from 'axios';
import { IMaster } from '../../types/profile';
import { baseAPIInstance } from '../index';

interface IEmployersAPI {
  getAll: () => Promise<AxiosResponse<IMaster[]>>;
  editMaster: (
    data: Partial<IMaster>,
    id: string | number
  ) => Promise<AxiosResponse>;
}

export const EmployersAPI: IEmployersAPI = {
  getAll: async () => {
    return baseAPIInstance.get('/account/masters');
  },
  editMaster: async (data, id) => {
    return baseAPIInstance.patch(`/account/${id}`, data);
  },
};
