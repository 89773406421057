import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProfile } from '../../types/profile';

export type IMeSuccessResponse = IProfile;

type InitialType = {
  isLoaded: boolean;
  isPending: boolean;
  isError: boolean;
  data: IMeSuccessResponse | null;
};

const initialState: InitialType = {
  isLoaded: false,
  isPending: false,
  isError: false,
  data: null,
};

const profileSlice = createSlice({
  name: 'profile/me',
  initialState: initialState as InitialType,
  reducers: {
    startMeWorker(state) {
      state.isPending = true;
    },
    setData(state, action: PayloadAction<IMeSuccessResponse>) {
      state.data = action.payload;
      state.isError = false;
      state.isLoaded = true;
    },
    endMeWorker(state) {
      state.isPending = false;
    },
    setError(state) {
      state.isLoaded = false;
      state.isError = true;
    },
  },
});

export const { startMeWorker, endMeWorker, setData, setError } =
  profileSlice.actions;
export const profileReducer = profileSlice.reducer;
