import { editMasterAction } from '../../state/masters/mastersList/actions';
import { call, put } from 'redux-saga/effects';
import {
  editMaster,
  endMastersWorker,
  startMastersWorker,
} from '../../state/masters/mastersList/slice';
import { EmployersAPI } from '../../api/employers';
import { message } from 'antd';
import { Role } from '../../types/roles';
import { setNeedCustomersLoad } from '../../state/customers/customersList/slice';
import { setNeedClientsLoad } from '../../state/clients/clientsList/slice';
import { setNeedSELoad } from '../../state/self-employers/list/slice';

export function* editWorker(
  action: ReturnType<typeof editMasterAction>
): Generator {
  try {
    yield put(startMastersWorker());
    yield call(
      EmployersAPI.editMaster,
      action.payload.newValues,
      action.payload.master.id
    );

    yield put(
      editMaster({
        ...action.payload.master,
        ...action.payload.newValues,
      })
    );

    if (action.payload.newValues.role !== Role.MASTER) {
      switch (action.payload.newValues.role) {
        case Role.SELF_EMPLOYER:
          yield put(setNeedSELoad());
          break;
        case Role.CUSTOMER:
          yield put(setNeedCustomersLoad());
          break;
        case Role.CLIENT:
          yield put(setNeedClientsLoad());
          break;
      }
    }
    yield put(endMastersWorker());
    yield call(message.success, 'Мастер успешно изменен');
  } catch (e) {
    yield put(endMastersWorker());
    yield call(message.error, 'Ошибка при изменении мастера');
  }
}
