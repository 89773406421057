import { IMaster } from '../../../types/profile';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Role } from '../../../types/roles';

type InitialType = {
  isLoaded: boolean;
  isPending: boolean;
  isError: boolean;
  masters: IMaster[] | null;
};

const initialState: InitialType = {
  isLoaded: false,
  isPending: false,
  isError: false,
  masters: null,
};

const mastersSlice = createSlice({
  name: 'masters/list',
  initialState: initialState as InitialType,
  reducers: {
    startMastersWorker(state) {
      state.isPending = true;
    },
    setData(state, action: PayloadAction<IMaster[]>) {
      state.masters = action.payload;
      state.isError = false;
      state.isLoaded = true;
    },
    endMastersWorker(state) {
      state.isPending = false;
    },
    setError(state) {
      state.isError = true;
      state.isLoaded = false;
    },
    editMaster(state, action: PayloadAction<IMaster>) {
      if (action.payload.role !== Role.MASTER) {
        state.masters = (state.masters ?? []).filter(
          (el) => el.id !== action.payload.id
        );
      } else {
        state.masters = (state.masters ?? []).map((el) => {
          if (el.id === action.payload.id) {
            return action.payload;
          } else return el;
        });
      }
    },
    setNeedMastersLoad(state) {
      state.isLoaded = false;
    },
  },
});

export const {
  startMastersWorker,
  endMastersWorker,
  editMaster,
  setData,
  setError,
  setNeedMastersLoad,
} = mastersSlice.actions;
export const mastersListReducer = mastersSlice.reducer;
