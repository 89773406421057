import { call, put } from 'redux-saga/effects';
import {
  startActionsGeneralListWorker,
  setActionsGeneralData,
  setActionsGeneralError,
  endActionsGeneralListWorker,
} from '../../../state/home-settings/actionsGeneral/slice';
import { HomeSettingsAPI } from '../../../api/home-settings';

export function* generalLoadAllWorker(): Generator {
  try {
    yield put(startActionsGeneralListWorker());

    const response: any = yield call(HomeSettingsAPI.getAllAGeneral);

    yield put(setActionsGeneralData(response.data));

    yield put(endActionsGeneralListWorker());
  } catch (e) {
    yield put(setActionsGeneralError());
    yield put(endActionsGeneralListWorker());
  }
}
