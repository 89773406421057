import React, { FC, useMemo } from 'react';
import {
  Avatar,
  Dropdown,
  Layout,
  MenuProps,
  Skeleton,
  Space,
  Typography,
} from 'antd';
import styled from '@emotion/styled';
import { LayoutMenu } from '../menu';
import logo from '../../assets/img/logo.png';
import { Outlet } from 'react-router-dom';
import { useProfileSelector } from '../../state/profile/selectors';
import { DownOutlined } from '@ant-design/icons';
import { useAppDispatch } from '../../state';
import { handleLogout } from '../../state/auth/slice';
import { getMediaFilePath } from '../../utils/common';

const { Header, Sider, Content } = Layout;

const headerStyle: React.CSSProperties = {
  color: '#fff',
  height: 64,
  paddingInline: 50,
  backgroundColor: '#001529',
  paddingTop: 16,
  lineHeight: 1.2,
};

const ContentStyled = styled(Content)`
  height: calc(100vh - 64px);
  overflow-y: auto;
`;

const SiderWrapper = styled(Sider)`
  width: 200px;
`;

const HeaderInner = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-space-item {
    height: fit-content;
  }

  .ant-avatar {
    background-color: rgba(202, 202, 202, 0.25);
    vertical-align: unset;
  }

  .ant-typography {
    vertical-align: unset;
  }
`;

const Logo = styled('div')`
  width: 200px;
  height: 64px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    object-fit: cover;
    width: 100%;
  }
`;

export const MainLayout: FC = () => {
  const dispatch = useAppDispatch();

  const { data, isLoaded, isPending } = useProfileSelector();

  const avatarComponent = useMemo(() => {
    if (isPending) {
      return (
        <Space size={8} align={'center'}>
          <Skeleton.Avatar active size={32} shape={'circle'} />
          <Skeleton.Input active />
        </Space>
      );
    }

    if (!isLoaded || !data) {
      return null;
    }

    let letter = data.phone[0];
    let name = data.phone;

    if (data.firstName) {
      if (data.lastName) {
        letter = `${data.firstName[0]}${data.lastName[0]}`;
        name = `${data.firstName} ${data.lastName}`;
      } else {
        letter = data.firstName[0];
        name = data.firstName;
      }
    } else if (data.email) {
      letter = data.email[0];
      name = data.email;
    }

    const items: MenuProps['items'] = [
      {
        key: 'logout',
        label: <span>Выйти из аккаунта</span>,
        onClick: () => {
          dispatch(handleLogout());
        },
      },
    ];

    return (
      <Dropdown menu={{ items }} trigger={'click' as any}>
        <Space
          size={8}
          align={'center'}
          style={{
            cursor: 'pointer',
          }}
        >
          <Avatar
            size={32}
            src={
              data.avatar ? getMediaFilePath(data.avatar.fileName) : undefined
            }
          >
            {data.avatar ? null : letter}
          </Avatar>
          <Space size={2} align={'center'}>
            <Typography.Text
              style={{
                color: '#d8d8d8',
              }}
            >
              {name}
            </Typography.Text>
            <DownOutlined
              style={{
                fontSize: 10,
              }}
            />
          </Space>
        </Space>
      </Dropdown>
    );
  }, [isLoaded, data, isPending]);

  return (
    <Layout>
      <SiderWrapper>
        <Logo>
          <img src={logo} alt="Site logo" />
        </Logo>
        <LayoutMenu />
      </SiderWrapper>
      <Layout>
        <Header style={headerStyle}>
          <HeaderInner>{avatarComponent}</HeaderInner>
        </Header>
        <ContentStyled>
          <Outlet />
        </ContentStyled>
      </Layout>
    </Layout>
  );
};
