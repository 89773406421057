import React, { FC, useCallback, useEffect, useState } from 'react';
import { IActionFromSalon } from '../../state/home-settings/actionsList/slice';
import { Button, Drawer, Form, message, Space, UploadFile } from 'antd';
import { ActionCeForm } from './action-ce-form';
import { useAppDispatch } from '../../state';
import {
  createActionAction,
  editActionAction,
} from '../../state/home-settings/actionsList/actions';
import { loadSalonByIdAction } from '../../state/salons/currentSalon/actions';

export interface IActionBaseData {
  title: string;
  description: string;
  terms: string;
  salonId: number | string;
  serviceStatus: number;
  status: 'Включена';
}

export interface IActionFirstCreate extends IActionBaseData {
  thumbs: File;
  fullImage: File;
}

export interface IActionFinalCreate extends IActionBaseData {
  thumbs: string;
  fullImage: string;
}

type Props =
  | {
      isOpen: boolean;
      onClose: () => void;
      mode: 'create';
      currentAction: undefined;
      salonId: number | string;
    }
  | {
      isOpen: boolean;
      onClose: () => void;
      mode: 'edit';
      currentAction?: IActionFromSalon;
      salonId: number | string;
    };

export interface IActionForm {
  title: string;
  description: string;
  terms: string;
  thumbs: [UploadFile];
  fullImage: [UploadFile];
  serviceStatus: number;
}

export const ActionDrawer: FC<Props> = ({
  onClose,
  currentAction,
  isOpen,
  mode,
  salonId,
}) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<IActionForm>();

  useEffect(() => {
    if (mode === 'edit' && currentAction) {
      form.setFieldsValue({
        title: currentAction.title,
        description: currentAction.description,
        terms: currentAction.terms,
        serviceStatus: currentAction.serviceStatus,
      });
    }
  }, [mode, currentAction]);

  const handleFinishForm = useCallback(
    (values: IActionForm) => {
      if (mode === 'create') {
        const data: IActionFirstCreate = {
          ...values,
          thumbs: values.thumbs[0].originFileObj as File,
          fullImage: values.fullImage[0].originFileObj as File,
          status: 'Включена',
          salonId: salonId,
        };

        const loading = message.loading('Создание акции', 0);
        setLoading(true);

        dispatch(
          createActionAction({
            data: data,
            errorCallBack: () => {
              loading();
              setLoading(false);
              void message.error('Ошибка при создании акции');
            },
            successCallBack: () => {
              loading();
              setLoading(false);
              dispatch(
                loadSalonByIdAction({
                  id: salonId,
                })
              );
              void message.success('Акция успешно создана');
              onClose();
            },
          })
        );
      } else if (mode === 'edit' && currentAction) {
        const data: Partial<IActionFirstCreate> = {
          ...values,
          thumbs:
            values.thumbs && values.thumbs.length > 0
              ? (values.thumbs[0].originFileObj as File)
              : undefined,
          fullImage:
            values.fullImage && values.fullImage.length > 0
              ? (values.fullImage[0].originFileObj as File)
              : undefined,
          status: 'Включена',
          salonId: salonId,
        };

        const loading = message.loading('Изменение акции', 0);
        setLoading(true);

        dispatch(
          editActionAction({
            data: data,
            originalFileObj: currentAction,
            errorCallBack: () => {
              loading();
              setLoading(false);
              void message.error('Ошибка при изменении акции');
            },
            successCallBack: () => {
              loading();
              setLoading(false);
              dispatch(
                loadSalonByIdAction({
                  id: salonId,
                })
              );
              void message.success('Акция успешно изменена');
              onClose();
            },
          })
        );
      }
    },
    [mode, currentAction, salonId]
  );

  return (
    <Drawer
      onClose={onClose}
      open={isOpen}
      maskClosable={false}
      closable={false}
      title={mode === 'create' ? 'Новая акция' : 'Изменить акцию'}
      width={700}
      extra={
        <Space>
          <Button onClick={onClose} loading={loading}>
            Отмена
          </Button>
          <Button type="primary" onClick={form.submit} loading={loading}>
            {mode === 'create' ? 'Создать' : 'Изменить'}
          </Button>
        </Space>
      }
    >
      {isOpen && (
        <ActionCeForm
          form={form}
          handleFinishForm={handleFinishForm}
          mode={mode}
          defaultFileFullUrl={
            mode === 'edit' && currentAction
              ? currentAction.fullImage
              : undefined
          }
          defaultFilePreviewUrl={
            mode === 'edit' && currentAction ? currentAction.thumbs : undefined
          }
        />
      )}
    </Drawer>
  );
};
