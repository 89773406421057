import React, { FC, PropsWithChildren, Fragment } from 'react';
import { ThemeService } from './theme-service';
import { LoginService } from './login-service';
import { CommonRequestsService } from './common-requests-service';

export const RootServices: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Fragment>
      <LoginService>
        <ThemeService>
          <CommonRequestsService>{children}</CommonRequestsService>
        </ThemeService>
      </LoginService>
    </Fragment>
  );
};
