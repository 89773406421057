import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IListedSalon } from '../../../types/salon';
import { ISalonEdited } from '../../../components/salons/edit-salon-drawer';

export interface ISalonsResponse {
  salons: IListedSalon[];
}

interface IState {
  isLoaded: boolean;
  isPending: boolean;
  isError: boolean;
  salons: IListedSalon[];
}

const initialState: IState = {
  isLoaded: false,
  isPending: false,
  isError: false,
  salons: [],
};

const salonsListSlice = createSlice({
  name: 'salons/all',
  initialState: initialState as IState,
  reducers: {
    startLoadWorker: (state) => {
      state.isPending = true;
    },
    endLoadWorker: (state) => {
      state.isPending = false;
    },
    setError: (state) => {
      state.isError = true;
      state.isLoaded = false;
    },
    setList: (state, action: PayloadAction<ISalonsResponse>) => {
      state.salons = action.payload.salons;
      state.isLoaded = true;
    },
    createSalon: (state, action: PayloadAction<IListedSalon>) => {
      state.salons = [...state.salons, action.payload];
    },
    deleteSalon: (state, action: PayloadAction<string | number>) => {
      state.salons = state.salons.filter((el) => el.id !== action.payload);
    },
    editListItem: (
      state,
      action: PayloadAction<{
        newData: ISalonEdited;
        salonId: string | number;
      }>
    ) => {
      state.salons = state.salons.map((el) => {
        if (el.id === action.payload.salonId) {
          return {
            ...el,
            ...action.payload.newData,
          };
        } else return el;
      });
    },
  },
});

export const {
  startLoadWorker,
  endLoadWorker,
  setList,
  setError,
  editListItem,
  createSalon,
  deleteSalon,
} = salonsListSlice.actions;
export const salonsListReducer = salonsListSlice.reducer;
