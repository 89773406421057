import { call, put } from 'redux-saga/effects';
import {
  setBannersError,
  startBannersWorker,
  endBannersWorker,
  setBannersData,
} from '../../../state/home-settings/banners/slice';
import { HomeSettingsAPI } from '../../../api/home-settings';
import { loadAllBannersAction } from '../../../state/home-settings/banners/actions';

export function* bannersAllLoadWorker(
  action: ReturnType<typeof loadAllBannersAction>
): Generator {
  try {
    yield put(startBannersWorker());

    const response: any = yield call(HomeSettingsAPI.getAllBanners);

    yield put(setBannersData(response.data));
    yield put(endBannersWorker());

    if (action.payload.successCallBack) {
      yield call(action.payload.successCallBack);
    }
  } catch (e) {
    yield put(setBannersError());
    yield put(endBannersWorker());
    if (action.payload.errorCallBack) {
      yield call(action.payload.errorCallBack);
    }
  }
}
