import { Provider } from 'react-redux';
import React, { Fragment } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistedStore, store } from './state';
import { RootServices } from './services/root-services';
import { RootRoutes } from './utils/routes';
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';

import './index.scss';
import 'antd/dist/reset.css';

import dayjs from 'dayjs';
import locale from 'antd/locale/ru_RU';
import 'dayjs/locale/ru';

dayjs.locale('ru');

const container = document.getElementById('root');

function App() {
  return (
    <Fragment>
      <Provider store={store}>
        <PersistGate persistor={persistedStore} loading={null}>
          <BrowserRouter>
            <ConfigProvider locale={locale}>
              <RootServices>
                <RootRoutes />
              </RootServices>
            </ConfigProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </Fragment>
  );
}

if (container) {
  const root = createRoot(container);

  root.render(<App />);
} else {
  throw new Error("Корневой элемент c id='root' не найден");
}
