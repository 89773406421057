import { AxiosResponse } from 'axios';
import { baseAPIInstance } from '../index';
import { IClientsResponse } from '../../state/clients/clientsList/slice';
import { IClient } from '../../types/profile';

interface IClientsAPI {
  getAll: () => Promise<AxiosResponse<IClientsResponse>>;
  editClient: (
    data: Partial<IClient>,
    id: string | number
  ) => Promise<AxiosResponse>;
}

export const ClientsAPI: IClientsAPI = {
  getAll: async () => {
    return baseAPIInstance.get('/client/all');
  },
  editClient: async (data, id) => {
    return baseAPIInstance.patch(`/account/${id}`, data);
  },
};
