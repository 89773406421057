import { editSalonMainDataByIdAction } from '../../state/salons/currentSalon/actions';
import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import {
  editSalon,
  endSalonLoadWorker,
  startSalonLoadWorker,
} from '../../state/salons/currentSalon/slice';
import { SalonAPI } from '../../api/salon';
import {
  editListItem,
  endLoadWorker,
  startLoadWorker,
} from '../../state/salons/salonsList/slice';

export function* editSalonMainDataWorker(
  action: ReturnType<typeof editSalonMainDataByIdAction>
): Generator {
  try {
    yield put(startSalonLoadWorker());
    yield put(startLoadWorker());

    yield call(
      SalonAPI.editSalonMainInfo,
      action.payload.newData,
      action.payload.salonId
    );

    yield put(
      editSalon({
        data: action.payload.newData,
        id: action.payload.salonId,
      })
    );
    yield put(
      editListItem({
        newData: action.payload.newData,
        salonId: action.payload.salonId,
      })
    );

    yield put(endLoadWorker());
    yield put(endSalonLoadWorker());
    if (action.payload.successCallBack) {
      yield call(action.payload.successCallBack);
    }
  } catch (e) {
    yield put(endLoadWorker());
    yield put(endSalonLoadWorker());
    if (action.payload.errorCallBack) {
      yield call(action.payload.errorCallBack);
    }
    yield call(message.error, 'Ошибка при изменении информации о салоне');
  }
}
