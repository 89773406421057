import React, { FC, useCallback, useEffect } from 'react';
import { Form, Input, message, Modal } from 'antd';
import { HomeSettingsAPI } from '../../api/home-settings';
import { useAppDispatch } from '../../state';
import { loadAllActionsGeneralAction } from '../../state/home-settings/actionsGeneral/actions';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedMeta: {
    actionId?: string | number;
    genId?: string | number;
    order: number;
  };
}

export const GeneralActionModal: FC<Props> = ({
  open,
  onClose,
  selectedMeta,
}) => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm<{
    actionsIds: string | number;
  }>();

  const handleOk = useCallback(() => {
    form.submit();
  }, []);

  const onCancel = useCallback(() => {
    onClose();
  }, []);

  useEffect(() => {
    if (selectedMeta.actionId) {
      form.setFieldValue('actionsIds', selectedMeta.actionId);
    } else {
      form.setFieldValue('actionsIds', undefined);
    }
  }, [selectedMeta]);

  const handleFormFinish = useCallback(
    async (values: { actionsIds: string | number }) => {
      if (selectedMeta.actionId && selectedMeta.genId) {
        const loading = message.loading('Изменение', 0);

        try {
          await HomeSettingsAPI.editGeneral(
            selectedMeta.order,
            values.actionsIds,
            selectedMeta.genId
          );

          loading();
          void message.success('Акция на главной изменена');
          onClose();
          dispatch(loadAllActionsGeneralAction());
        } catch (e) {
          loading();
          void message.error('Ошибка при изменении акции на главной');
        }
      } else {
        const loading = message.loading('Создание', 0);

        try {
          await HomeSettingsAPI.createGeneral(
            selectedMeta.order,
            values.actionsIds
          );
          loading();
          void message.success('Акция добавлена на главную');
          onClose();
          dispatch(loadAllActionsGeneralAction());
        } catch (e) {
          loading();
          void message.error('Ошибка при добавлении акции на главную');
        }
      }
    },
    [selectedMeta]
  );

  return (
    <Modal
      title={
        selectedMeta.actionId
          ? `Изменить слот ${selectedMeta.order + 1}`
          : `Создать слот ${selectedMeta.order + 1}`
      }
      open={open}
      onOk={handleOk}
      onCancel={onCancel}
      destroyOnClose={true}
      afterClose={onClose}
      okText={selectedMeta.actionId ? 'Изменить' : 'Создать'}
      cancelText={'Отмена'}
      centered
    >
      <Form<{
        actionsIds: string | number;
      }>
        form={form}
        layout={'vertical'}
        onFinish={handleFormFinish}
      >
        <Form.Item
          label={'ID акции'}
          name={'actionsIds'}
          rules={[
            {
              required: true,
              message: 'Поле обязательно для заполнения',
            },
          ]}
        >
          <Input placeholder={'Введите'} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
