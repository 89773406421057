import { baseAPIInstance } from '../index';
import { AxiosResponse } from 'axios';
import { ILoginSuccessResponse } from '../../state/auth/slice';

interface IAuthAPI {
  login: (props: {
    publicKey: string;
    secretKey: string;
  }) => Promise<AxiosResponse<ILoginSuccessResponse>>;
}

export const AuthAPI: IAuthAPI = {
  login: async (data) => {
    return baseAPIInstance.post('/auth/signin', data);
  },
};
