import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClient } from '../../../types/profile';
import { Role } from '../../../types/roles';

export type IClientsResponse = {
  clients: IClient[];
};

type InitialType = {
  isLoaded: boolean;
  isPending: boolean;
  isError: boolean;
  clients: IClient[] | null;
};

const initialState: InitialType = {
  isLoaded: false,
  isPending: false,
  isError: false,
  clients: null,
};

const clientsSlice = createSlice({
  name: 'clients/all',
  initialState: initialState as InitialType,
  reducers: {
    startClientsWorker(state) {
      state.isPending = true;
    },
    setData(state, action: PayloadAction<IClientsResponse>) {
      state.clients = action.payload.clients;
      state.isError = false;
      state.isLoaded = true;
    },
    endClientsWorker(state) {
      state.isPending = false;
    },
    setError(state) {
      state.isLoaded = false;
      state.isError = true;
    },
    editClient(state, action: PayloadAction<IClient>) {
      if (action.payload.role !== Role.CLIENT) {
        state.clients = (state.clients ?? []).filter(
          (el) => el.id !== action.payload.id
        );
      } else {
        state.clients = (state.clients ?? []).map((el) => {
          if (el.id === action.payload.id) {
            return action.payload;
          } else return el;
        });
      }
    },
    setNeedClientsLoad(state) {
      state.isLoaded = false;
    },
  },
});

export const {
  startClientsWorker,
  endClientsWorker,
  setData,
  setError,
  editClient,
  setNeedClientsLoad,
} = clientsSlice.actions;
export const clientsListReducer = clientsSlice.reducer;
