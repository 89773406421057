import React, { FC, useCallback } from 'react';
import { Button, Drawer, Form, message, Space } from 'antd';
import { ISalonForm } from './edit-salon-drawer';
import { SalonDataForm } from './salon-data-form';
import { useAppDispatch } from '../../state';
import { createSalonAction } from '../../state/salons/currentSalon/actions';

interface Props {
  createDrawerOpen: boolean;
  onClose: () => void;
}

export const CreateSalonDrawer: FC<Props> = ({ createDrawerOpen, onClose }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<ISalonForm>();

  const handleFormFinish = useCallback(async (values: ISalonForm) => {
    const loading = message.loading('Создание салона', 0);
    dispatch(
      createSalonAction({
        newData: {
          ...values,
          startJobTime: values.startJobTime?.format('HH:mm'),
          endJobTime: values.endJobTime?.format('HH:mm'),
        },
        successCallBack: () => {
          onClose();
          loading();
          message.success('Салон успешно создан');
        },
        errorCallBack: () => {
          loading();
          message.error('Ошибка при создании салона');
        },
      })
    );
  }, []);

  return (
    <Drawer
      onClose={onClose}
      open={createDrawerOpen}
      maskClosable={false}
      closable={false}
      title={'Создание салона'}
      width={700}
      extra={
        <Space>
          <Button onClick={onClose}>Отмена</Button>
          <Button type="primary" onClick={form.submit}>
            Создать
          </Button>
        </Space>
      }
    >
      {createDrawerOpen && (
        <SalonDataForm form={form} onFinish={handleFormFinish} />
      )}
    </Drawer>
  );
};
