import { IDecodedToken } from '../../state/auth/slice';
import { mediaFileHost } from '../../api';

export const localStoragePaths = {
  accessTokenPath: 'accessToken',
  refreshTokenPath: 'refreshToken',
} as const;

export const parseJwt: (token: string) => IDecodedToken = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  return JSON.parse(
    decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    )
  );
};

export const getMediaFilePath: (fileName: string) => string = (fn) => {
  return `${mediaFileHost}${fn}`;
};
