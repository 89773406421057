import storage from 'redux-persist/lib/storage';
import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import { handleLogout, userAuthReducer } from './auth/slice';
import { applicationReducers } from './app';
import { profileReducer } from './profile/slice';
import { clientsReducer } from './clients';
import { customerReducer } from './customers';
import { mastersReducer } from './masters';
import { salonsReducer } from './salons';
import { homeSettingsReducer } from './home-settings';
import { selfEmployersReducers } from './self-employers';

const userAuthConfig = {
  key: 'user/auth',
  storage,
};

const appReducer = combineReducers({
  userAuth: persistReducer(userAuthConfig, userAuthReducer),
  app: applicationReducers,
  profile: profileReducer,
  clients: clientsReducer,
  customers: customerReducer,
  masters: mastersReducer,
  salons: salonsReducer,
  homeSettings: homeSettingsReducer,
  selfEmployers: selfEmployersReducers,
});

export type RootCombine = ReturnType<typeof appReducer>;

export const rootReducer: Reducer = (state: RootCombine, action) => {
  if (action.type === handleLogout.type) {
    localStorage.clear();
  }

  return appReducer(state, action);
};
