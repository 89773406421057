import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../state';
import { useSalonsListSelector } from '../state/salons/salonsList/selectors';
import { loadAllSalonsAction } from '../state/salons/salonsList/actions';
import { TableWrapper, Wrapper } from './clients';
import { Button, Space, Table, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { IListedSalon } from '../types/salon';
import { loadSalonByIdAction } from '../state/salons/currentSalon/actions';
import { useCurrentSalon } from '../state/salons/currentSalon/selectors';
import { EditSalonDrawer } from '../components/salons/edit-salon-drawer';
import { CreateSalonDrawer } from '../components/salons/create-salon-drawer';
import { useCustomersListSelector } from '../state/customers/customersList/selectors';
import { loadAllCustomersAction } from '../state/customers/customersList/actions';
import { useSESelector } from '../state/self-employers/list/selectors';
import { loadAllSEAction } from '../state/self-employers/list/actions';

const salonColumns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Название',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Владелец',
    dataIndex: 'owner',
    key: 'owner',
  },
  {
    title: 'Рейтинг',
    dataIndex: 'rating',
    key: 'rating',
  },
  {
    title: 'Время работы',
    dataIndex: 'workTime',
    key: 'workTime',
  },
  {
    title: 'Город',
    dataIndex: 'city',
    key: 'city',
  },
  {
    title: 'Метро',
    dataIndex: 'metro',
    key: 'metro',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Номер телефона',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
  },
];

export const Salons: FC = () => {
  const dispatch = useAppDispatch();
  const { isLoaded, salons, isPending, isError } = useSalonsListSelector();
  const currentSalonInfo = useCurrentSalon();

  const customersData = useCustomersListSelector();
  const selfEmployersData = useSESelector();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [createDrawerOpen, setCreateDrawerOpen] = useState(false);

  const onDrawerClose = useCallback(() => {
    setDrawerOpen(false);
  }, []);

  const onCreateDrawerClose = useCallback(() => {
    setCreateDrawerOpen(false);
  }, []);

  const handleOpenCreateDrawer = useCallback(() => {
    setCreateDrawerOpen(true);
  }, []);

  const handleOpenDrawer = useCallback(() => {
    setDrawerOpen(true);
  }, []);

  useEffect(() => {
    if (!isLoaded && !isPending && !isError) {
      dispatch(loadAllSalonsAction());
    }
  }, [isLoaded, isError, isPending]);

  const handleClickSalon = useCallback(
    (salon: IListedSalon) => {
      if (
        currentSalonInfo.isLoaded &&
        !currentSalonInfo.isPending &&
        currentSalonInfo.data.id === salon.id
      ) {
        handleOpenDrawer();
      } else {
        handleOpenDrawer();
        dispatch(
          loadSalonByIdAction({
            id: salon.id,
            errorCallBack: () => {
              onDrawerClose();
            },
          })
        );
      }
    },
    [currentSalonInfo]
  );

  useEffect(() => {
    if (
      !customersData.isLoaded &&
      !customersData.isError &&
      !customersData.isPending
    ) {
      dispatch(loadAllCustomersAction());
    }
  }, [customersData]);

  useEffect(() => {
    if (
      !selfEmployersData.isLoaded &&
      !selfEmployersData.isError &&
      !selfEmployersData.isPending
    ) {
      dispatch(loadAllSEAction());
    }
  }, [selfEmployersData]);

  const dataSource = useMemo(
    () =>
      salons.map((el) => ({
        title: el.title,
        owner: `${el.owner.firstName} ${el.owner.lastName}`,
        rating: el.rating,
        workTime: `${el.startJobTime ?? 'Не указано'}-${
          el.endJobTime ?? 'Не указано'
        }`,
        city: el.city,
        metro: el.metro,
        email: el.email,
        phone: el.phone,
        key: el.id,
        id: el.id,
        actions: (
          <Button
            shape={'circle'}
            onClick={() => {
              handleClickSalon(el);
            }}
          >
            <EditOutlined />
          </Button>
        ),
      })),
    [salons, handleClickSalon]
  );

  return (
    <Wrapper>
      <Space
        style={{
          justifyContent: 'space-between',
        }}
      >
        <Typography.Title level={2}>Салоны в системе</Typography.Title>
        <Button type={'primary'} onClick={handleOpenCreateDrawer}>
          Создать
        </Button>
      </Space>
      <TableWrapper>
        <Table
          pagination={false}
          loading={isPending}
          columns={salonColumns}
          dataSource={dataSource}
          locale={{
            emptyText: 'Нет данных',
          }}
        />
      </TableWrapper>
      <EditSalonDrawer open={drawerOpen} onClose={onDrawerClose} />
      <CreateSalonDrawer
        createDrawerOpen={createDrawerOpen}
        onClose={onCreateDrawerClose}
      />
    </Wrapper>
  );
};
