import React, { FC, useCallback, useEffect } from 'react';
import { Button, Drawer, Form, message, Space, UploadFile } from 'antd';
import { BannerCeForm } from './banner-ce-form';
import { IBanner } from '../../state/home-settings/banners/slice';
import { getMediaFilePath } from '../../utils/common';
import { CommonAPI, IMediaType } from '../../api/common';
import { useAppDispatch } from '../../state';
import {
  createBannerAction,
  editBannerAction,
} from '../../state/home-settings/banners/actions';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  mode: 'create' | 'edit';
  currentBanner?: IBanner;
}

export interface IBannerForm {
  url: string;
  mediaList: [UploadFile];
}

export const BannerDrawer: FC<Props> = ({
  onClose,
  isOpen,
  mode,
  currentBanner,
}) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<IBannerForm>();

  useEffect(() => {
    if (mode === 'edit' && currentBanner) {
      form.setFieldsValue({
        url: currentBanner.url,
        mediaList: [],
      });
    }
  }, [mode, currentBanner]);

  const handleFinishForm = useCallback(
    async (values: IBannerForm) => {
      const loading = message.loading(
        `${mode === 'create' ? 'Создание' : 'Изменение'} баннера`
      );
      if (mode === 'edit' && currentBanner) {
        if (
          values.mediaList?.length === 1 &&
          values.mediaList[0].originFileObj
        ) {
          try {
            await CommonAPI.deleteFile(currentBanner.media.id);

            const response = await CommonAPI.uploadFile(
              IMediaType.BANNER,
              values.mediaList[0].originFileObj
            );

            dispatch(
              editBannerAction({
                originalBannerObj: currentBanner,
                newData: {
                  url: values.url,
                  mediaId: response.data.id,
                },
                successCallBack: () => {
                  message.success('Баннер изменен');
                  onClose();
                  loading();
                },
                errorCallBack: () => {
                  message.error('Ошибка при изменении банера');
                  loading();
                },
              })
            );
          } catch (e) {
            loading();
            message.error('Ошибка при работе с файлами');
          }
        } else {
          dispatch(
            editBannerAction({
              originalBannerObj: currentBanner,
              newData: {
                url: values.url,
                mediaId: currentBanner.media.id,
              },
              successCallBack: () => {
                message.success('Баннер изменен');
                onClose();
                loading();
              },
              errorCallBack: () => {
                message.error('Ошибка при изменении банера');
                loading();
              },
            })
          );
        }
      } else if (mode === 'create') {
        if (
          values.mediaList?.length === 1 &&
          values.mediaList[0].originFileObj
        ) {
          const response = await CommonAPI.uploadFile(
            IMediaType.BANNER,
            values.mediaList[0].originFileObj
          );

          dispatch(
            createBannerAction({
              url: values.url,
              mediaId: response.data.id,
              successCallBack: () => {
                message.success('Баннер создан');
                onClose();
                loading();
              },
              errorCallBack: () => {
                message.error('Ошибка при создании банера');
                loading();
              },
            })
          );
        }
      }
    },
    [mode, currentBanner]
  );

  return (
    <Drawer
      onClose={onClose}
      open={isOpen}
      maskClosable={false}
      closable={false}
      title={mode === 'create' ? 'Новый баннер' : 'Изменить баннер'}
      width={700}
      extra={
        <Space>
          <Button onClick={onClose}>Отмена</Button>
          <Button type="primary" onClick={form.submit}>
            {mode === 'create' ? 'Создать' : 'Изменить'}
          </Button>
        </Space>
      }
    >
      {isOpen && (
        <BannerCeForm
          form={form}
          handleFinishForm={handleFinishForm}
          defaultFileUrl={
            mode === 'edit' && currentBanner
              ? getMediaFilePath(currentBanner.media.fileName)
              : undefined
          }
        />
      )}
    </Drawer>
  );
};
