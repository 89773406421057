import axios from 'axios';
import { localStoragePaths } from '../utils/common';

export const isProduction = process?.env.NODE_ENV !== 'development';

export const mainDomain = isProduction
  ? 'http://beautysputnik.ru/'
  : 'http://beautydev.ru/';

export const mediaFileHost =
  process?.env.REMOTE_MEDIA_HOST ?? 'http://storage.beautysputnik.ru/beauty/';

export const baseAPIInstance = axios.create({
  baseURL: process?.env.REACT_APP_REMOTE_HOST ?? 'http://77.222.52.193:8008/',
});

baseAPIInstance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    localStoragePaths.accessTokenPath
  )}`;
  return config;
});
