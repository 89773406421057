import { call, put, takeLeading } from 'redux-saga/effects';
import { localStoragePaths, parseJwt } from '../../utils/common';
import { AuthAPI } from '../../api/auth';
import { loginAction } from '../../state/auth/actions';
import { clearError, setAuth, setAuthError } from '../../state/auth/slice';
import { message } from 'antd';
import { Role } from '../../types/roles';

function* loginWorker(action: ReturnType<typeof loginAction>): Generator {
  try {
    yield put(clearError());

    const response: any = yield call(AuthAPI.login, {
      publicKey: action.payload.publicKey,
      secretKey: action.payload.secretKey,
    });

    const decoded = parseJwt(response.data.accessToken);

    localStorage.setItem(
      localStoragePaths.accessTokenPath,
      response.data.accessToken
    );
    localStorage.setItem(
      localStoragePaths.refreshTokenPath,
      response.data.refreshToken
    );

    if (
      decoded.role !== Role.ADMIN ||
      (Array.isArray(decoded.role) && decoded.role.indexOf(Role.ADMIN) === -1)
    ) {
      void message.error('Для просмотра содержимого недостаточно прав');

      if (action.payload.loginErrorCallBack) {
        yield call(action.payload.loginErrorCallBack);
      }
    } else {
      yield put(
        setAuth({
          data: response.data,
          decoded,
        })
      );

      if (action.payload.loginSuccessCallBack) {
        yield call(action.payload.loginSuccessCallBack);
      }
    }
  } catch (e: any) {
    console.log(e);

    if (e.response?.status === 401 || e.response?.status === 400) {
      void message.error('Не верный логин или пароль');
    }
    if (action.payload.loginErrorCallBack) {
      yield call(action.payload.loginErrorCallBack);
    }
    yield put(setAuthError({ status: e.response.status }));
  }
}

export function* authSaga(): Generator {
  yield takeLeading(loginAction, loginWorker);
}
