import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISelfEmployer } from '../../../types/profile';
import { Role } from '../../../types/roles';

type InitialType = {
  isLoaded: boolean;
  isPending: boolean;
  isError: boolean;
  selfEmployers: ISelfEmployer[] | null;
};

const initialState: InitialType = {
  isLoaded: false,
  isPending: false,
  isError: false,
  selfEmployers: null,
};

const selfEmployersListSlice = createSlice({
  name: 'selfEmployers/list',
  initialState: initialState as InitialType,
  reducers: {
    startSEWorker(state) {
      state.isPending = true;
    },
    setSEData(state, action: PayloadAction<ISelfEmployer[]>) {
      state.selfEmployers = action.payload.filter(
        (el) => el.role === Role.SELF_EMPLOYER
      );
      state.isError = false;
      state.isLoaded = true;
    },
    endSEWorker(state) {
      state.isPending = false;
    },
    setError(state) {
      state.isLoaded = false;
      state.isError = true;
    },
    editSE(state, action: PayloadAction<ISelfEmployer>) {
      if (action.payload.role !== Role.SELF_EMPLOYER) {
        state.selfEmployers = (state.selfEmployers ?? []).filter(
          (el) => el.id !== action.payload.id
        );
      } else {
        state.selfEmployers = (state.selfEmployers ?? []).map((el) => {
          if (el.id === action.payload.id) {
            return action.payload;
          } else return el;
        });
      }
    },
    setNeedSELoad(state) {
      state.isLoaded = false;
    },
  },
});

export const {
  startSEWorker,
  endSEWorker,
  editSE,
  setSEData,
  setNeedSELoad,
  setError,
} = selfEmployersListSlice.actions;
export const seListReducer = selfEmployersListSlice.reducer;
